/**
 * Created by User on 19/10/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

class ProjectCard extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    render() {
        return <Card variant={"elevation"}>
            <CardContent>
                <Typography variant={"h6"}>{this.props.project.name}</Typography>
                <br/>
                { this.props.project.creator_email &&
                    <Typography variant={"subtitle2"}>Creator: {this.props.project.creator_email}</Typography>
                }
            </CardContent>
            <CardActions >
                <Button color={"primary"} style={{float:"right"}} onClick={this.props.onClick}>
                    select
                </Button>
            </CardActions>
        </Card>;
    }
}


ProjectCard.propTypes = {
    project: PropTypes.object,
    onClick: PropTypes.func,
}


export default ProjectCard;
