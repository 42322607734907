const _ = (i,a,b,c,d) => ({id:i, name: a, creator: b, city_count: c, cities:d})
const __ = (i,n,l) => ({id:i, name: n, location:l})
export const projects = [
    _(0,"Benchmark", "CRC-WSC", 3, [
        __(0, "Melbourne", [-37.813611, 144.963056]),
        __(1, "Geelong", [-38.15, 144.35]),
        __(2, "Sale", [-38.1, 147.066667]),
    ]),
    _(1, "Analysis", "K. Rudd", 17),
    _(2, "Water", "T. Abbott", 2),
    _(3, "Rivers", "S. Morrison", 5),
    _(4, "Deltas", "J. Gillard", 6),
]