/**
 * Created by User on 8/12/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {RadarChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, ResponsiveContainer, Tooltip} from "recharts";
import {calculate_continuum} from "../Continuum";

const radarPresentationData = [

    {
        "subject": "Ensure good water sensitive guidance"
    },
    {
        "subject": "Increase community capital"
    },
    {
        "subject": "Achieve equity of essential services"
    },
    {
        "subject": "Improve productivity and resource efficiency"
    },
    {
        "subject": "Improve ecological health"
    },
    {
        "subject": "Ensure quality urban space"
    },

    {
        "subject": "Promote adaptive infrastructure"
    },

]
class CityRadarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    calculateRadial = (base, actions) => {
        return base.map((v,i) => {
            return v.map((o,j) => {

                if(actions.length === 0){
                    return o.rating
                }
                return Math.max(...actions.map(d => d.ratings[i][j].rating_diff + o.rating))
            }).reduce((p,c) => p + c, 0)/v.length;
        })
    }

    render() {
        let radar;
        let base_radar, target_radar;
        console.log(this.props)
        if (this.props.managementActions.length > 0) {
            radar = this.calculateRadial(this.props.baseRatings, this.props.managementActions);
            base_radar = this.calculateRadial(this.props.baseRatings, []);
            target_radar = this.calculateRadial(this.props.targetRatings || [], []);
        }else{
            radar=this.calculateRadial(this.props.baseRatings, []);
            base_radar=this.calculateRadial(this.props.baseRatings, []);
            target_radar = this.calculateRadial(this.props.targetRatings||[], []);
        }

        return <ResponsiveContainer width={"100%"} height={"100%"}>

            <RadarChart outerRadius={"70%"} data={radar.map((v, i) => ({"T": target_radar[i],"D": base_radar[i], "V":v, ...radarPresentationData[i]}))}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject"
                                tick={_=><foreignObject style={{
                                    fontSize:"0.6em",
                                }}
                                                        x={_.textAnchor === "start" ? _.x : _.textAnchor === "end" ? _.x - 100: _.x - 50}
                                                        y={_.payload.coordinate > 0 || _.payload.coordinate < -180 ? _.y - 100: _.y}
                                                        height={100}
                                                        width={100}>
                                    <div style={{width:"100%", height:"100%",
                                        display:"flex",
                                        flexDirection:"column",}}>
                                        {_.payload.coordinate > 0 || _.payload.coordinate < -180 ? <div style={{flex:1}}/> : ""}
                                        <div style={{textAlign: _.textAnchor === "start" ? "left": _.textAnchor === "end"?"right": "center"}}>{_.payload.value}</div>
                                    </div>
                                </foreignObject>}/>

                <PolarRadiusAxis tickCount={5} angle={61} domain={[1, 5]}
                                 tick={_=><foreignObject style={{
                                     fontSize:"0.6em",
                                 }}>

                                 </foreignObject>}

                />
                <Radar animationDuration={200}  dataKey="V" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                <Radar animationDuration={200}  dataKey="D" fill="none"
                       stroke="rgb(0,89,200)"
                       strokeWidth={2}
                       strokeDasharray={4} fillOpacity={0.6} />
                <Radar animationDuration={200}  dataKey="T" fill="none"
                       stroke="rgb(255,89,0)"
                       strokeWidth={2}
                       strokeDasharray={4} fillOpacity={0.6} />

                <Tooltip formatter={(value, name, props) => {
                    return [Math.round(4*value)/4, {"D": "base", "T":"target", "V": "with actions"}[name]]
                }}/>

            </RadarChart>
        </ResponsiveContainer>
    }
}


CityRadarChart.propTypes = {}


export default CityRadarChart;
