const detail_ids = [
    [419, 420, 421, 422, 423, 424, 425],
    [426, 427, 428, 431, 432],
    [433, 434, 435, 436],
    [437, 438, 439, 440, 442],
    [443, 444, 445, 446],
    [447,448,449],
    [450,451,452,453,454,456],
]


const continuum_data = [{detail_id: 419, continuum_id: 0, threshold: 1},
    {detail_id: 420, continuum_id: 0, threshold: 1},
    {detail_id: 421, continuum_id: 0, threshold: 1},
    {detail_id: 426, continuum_id: 0, threshold: 1},
    {detail_id: 427, continuum_id: 0, threshold: 1},
    {detail_id: 428, continuum_id: 0, threshold: 1},
    {detail_id: 433, continuum_id: 0, threshold: 1},
    {detail_id: 434, continuum_id: 0, threshold: 1},
    {detail_id: 435, continuum_id: 0, threshold: 1},
    {detail_id: 438, continuum_id: 0, threshold: 1},
    {detail_id: 440, continuum_id: 0, threshold: 1},
    {detail_id: 442, continuum_id: 0, threshold: 1},
    {detail_id: 443, continuum_id: 0, threshold: 1},
    {detail_id: 444, continuum_id: 0, threshold: 1},
    {detail_id: 445, continuum_id: 0, threshold: 1},
    {detail_id: 447, continuum_id: 0, threshold: 1},
    {detail_id: 448, continuum_id: 0, threshold: 1},
    {detail_id: 450, continuum_id: 0, threshold: 1},
    {detail_id: 452, continuum_id: 0, threshold: 1},
    {detail_id: 453, continuum_id: 0, threshold: 1},
    {detail_id: 419, continuum_id: 1, threshold: 1},
    {detail_id: 420, continuum_id: 1, threshold: 1},
    {detail_id: 421, continuum_id: 1, threshold: 1},
    {detail_id: 426, continuum_id: 1, threshold: 1},
    {detail_id: 427, continuum_id: 1, threshold: 1},
    {detail_id: 428, continuum_id: 1, threshold: 1},
    {detail_id: 433, continuum_id: 1, threshold: 4},
    {detail_id: 435, continuum_id: 1, threshold: 1},
    {detail_id: 438, continuum_id: 1, threshold: 1},
    {detail_id: 440, continuum_id: 1, threshold: 1},
    {detail_id: 442, continuum_id: 1, threshold: 1},
    {detail_id: 443, continuum_id: 1, threshold: 1},
    {detail_id: 444, continuum_id: 1, threshold: 1},
    {detail_id: 445, continuum_id: 1, threshold: 1},
    {detail_id: 447, continuum_id: 1, threshold: 1},
    {detail_id: 448, continuum_id: 1, threshold: 1},
    {detail_id: 450, continuum_id: 1, threshold: 1},
    {detail_id: 434, continuum_id: 1, threshold: 1},
    {detail_id: 452, continuum_id: 1, threshold: 1},
    {detail_id: 453, continuum_id: 1, threshold: 1},
    {detail_id: 419, continuum_id: 2, threshold: 1},
    {detail_id: 420, continuum_id: 2, threshold: 1},
    {detail_id: 421, continuum_id: 2, threshold: 1},
    {detail_id: 426, continuum_id: 2, threshold: 1},
    {detail_id: 427, continuum_id: 2, threshold: 1},
    {detail_id: 428, continuum_id: 2, threshold: 1},
    {detail_id: 433, continuum_id: 2, threshold: 4},
    {detail_id: 434, continuum_id: 2, threshold: 4},
    {detail_id: 435, continuum_id: 2, threshold: 1},
    {detail_id: 438, continuum_id: 2, threshold: 1},
    {detail_id: 440, continuum_id: 2, threshold: 1},
    {detail_id: 442, continuum_id: 2, threshold: 1},
    {detail_id: 443, continuum_id: 2, threshold: 1},
    {detail_id: 444, continuum_id: 2, threshold: 1},
    {detail_id: 445, continuum_id: 2, threshold: 1},
    {detail_id: 447, continuum_id: 2, threshold: 1},
    {detail_id: 448, continuum_id: 2, threshold: 1},
    {detail_id: 450, continuum_id: 2, threshold: 1},
    {detail_id: 453, continuum_id: 2, threshold: 1},
    {detail_id: 452, continuum_id: 2, threshold: 1},
    {detail_id: 419, continuum_id: 3, threshold: 1},
    {detail_id: 420, continuum_id: 3, threshold: 1},
    {detail_id: 421, continuum_id: 3, threshold: 1},
    {detail_id: 426, continuum_id: 3, threshold: 1},
    {detail_id: 427, continuum_id: 3, threshold: 1},
    {detail_id: 428, continuum_id: 3, threshold: 1},
    {detail_id: 433, continuum_id: 3, threshold: 4},
    {detail_id: 434, continuum_id: 3, threshold: 4},
    {detail_id: 435, continuum_id: 3, threshold: 4},
    {detail_id: 438, continuum_id: 3, threshold: 1},
    {detail_id: 440, continuum_id: 3, threshold: 1},
    {detail_id: 442, continuum_id: 3, threshold: 1},
    {detail_id: 443, continuum_id: 3, threshold: 1},
    {detail_id: 444, continuum_id: 3, threshold: 1},
    {detail_id: 445, continuum_id: 3, threshold: 1},
    {detail_id: 447, continuum_id: 3, threshold: 1},
    {detail_id: 448, continuum_id: 3, threshold: 1},
    {detail_id: 450, continuum_id: 3, threshold: 1},
    {detail_id: 452, continuum_id: 3, threshold: 1},
    {detail_id: 453, continuum_id: 3, threshold: 1},
    {detail_id: 419, continuum_id: 4, threshold: 2},
    {detail_id: 420, continuum_id: 4, threshold: 2},
    {detail_id: 421, continuum_id: 4, threshold: 2},
    {detail_id: 426, continuum_id: 4, threshold: 3},
    {detail_id: 427, continuum_id: 4, threshold: 3},
    {detail_id: 428, continuum_id: 4, threshold: 2},
    {detail_id: 433, continuum_id: 4, threshold: 4},
    {detail_id: 434, continuum_id: 4, threshold: 4},
    {detail_id: 435, continuum_id: 4, threshold: 4},
    {detail_id: 438, continuum_id: 4, threshold: 2},
    {detail_id: 440, continuum_id: 4, threshold: 3},
    {detail_id: 442, continuum_id: 4, threshold: 2},
    {detail_id: 443, continuum_id: 4, threshold: 3},
    {detail_id: 444, continuum_id: 4, threshold: 3},
    {detail_id: 445, continuum_id: 4, threshold: 3},
    {detail_id: 447, continuum_id: 4, threshold: 2},
    {detail_id: 448, continuum_id: 4, threshold: 2},
    {detail_id: 450, continuum_id: 4, threshold: 2},
    {detail_id: 452, continuum_id: 4, threshold: 2},
    {detail_id: 453, continuum_id: 4, threshold: 2},
    {detail_id: 419, continuum_id: 5, threshold: 3},
    {detail_id: 420, continuum_id: 5, threshold: 3},
    {detail_id: 421, continuum_id: 5, threshold: 3},
    {detail_id: 426, continuum_id: 5, threshold: 4},
    {detail_id: 427, continuum_id: 5, threshold: 4},
    {detail_id: 428, continuum_id: 5, threshold: 3},
    {detail_id: 433, continuum_id: 5, threshold: 4},
    {detail_id: 434, continuum_id: 5, threshold: 4},
    {detail_id: 435, continuum_id: 5, threshold: 4},
    {detail_id: 438, continuum_id: 5, threshold: 3},
    {detail_id: 440, continuum_id: 5, threshold: 3},
    {detail_id: 442, continuum_id: 5, threshold: 3},
    {detail_id: 443, continuum_id: 5, threshold: 4},
    {detail_id: 444, continuum_id: 5, threshold: 4},
    {detail_id: 445, continuum_id: 5, threshold: 4},
    {detail_id: 447, continuum_id: 5, threshold: 3},
    {detail_id: 448, continuum_id: 5, threshold: 3},
    {detail_id: 450, continuum_id: 5, threshold: 3},
    {detail_id: 452, continuum_id: 5, threshold: 3},
    {detail_id: 453, continuum_id: 5, threshold: 3},
    {detail_id: 419, continuum_id: 6, threshold: 5},
    {detail_id: 420, continuum_id: 6, threshold: 5},
    {detail_id: 421, continuum_id: 6, threshold: 5},
    {detail_id: 426, continuum_id: 6, threshold: 5},
    {detail_id: 427, continuum_id: 6, threshold: 5},
    {detail_id: 428, continuum_id: 6, threshold: 5},
    {detail_id: 433, continuum_id: 6, threshold: 5},
    {detail_id: 434, continuum_id: 6, threshold: 5},
    {detail_id: 435, continuum_id: 6, threshold: 5},
    {detail_id: 438, continuum_id: 6, threshold: 5},
    {detail_id: 440, continuum_id: 6, threshold: 5},
    {detail_id: 442, continuum_id: 6, threshold: 5},
    {detail_id: 443, continuum_id: 6, threshold: 5},
    {detail_id: 444, continuum_id: 6, threshold: 5},
    {detail_id: 445, continuum_id: 6, threshold: 5},
    {detail_id: 447, continuum_id: 6, threshold: 5},
    {detail_id: 448, continuum_id: 6, threshold: 5},
    {detail_id: 450, continuum_id: 6, threshold: 5},
    {detail_id: 452, continuum_id: 6, threshold: 5},
    {detail_id: 453, continuum_id: 6, threshold: 5},
]

export const calculate_continuum = (base_vals, actions) => {
    let ave_actions;
    if(actions.length > 0) {
        ave_actions = actions[0].ratings.map((v, i) => {
            return v.map((r, j) => {
                return Math.max(...actions.map(action => {
                    return action.ratings[i][j].rating_diff
                }));
            })
        })
    }else{
        ave_actions = [
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0],
            [0, 0, 0, 0, 0, 0],
        ]
    }
    let detail_index_map = {};
    detail_ids.map((d,i) => {
        d.map((v,j) => detail_index_map[v] = [i,j])
    })

    let num_continuums = 7;
    let cont_op = [];
    let index = 0;
    for(let i = 0; i < num_continuums; i++){
        let sum = 0;
        while(index < continuum_data.length &&  continuum_data[index].continuum_id === i ){
            let detail_index = detail_index_map[continuum_data[index].detail_id];
            if(base_vals[detail_index[0]][detail_index[1]].rating + ave_actions[detail_index[0]][detail_index[1]] >= continuum_data[index].threshold){
                sum += 1;
            }
            index += 1;
        }

        cont_op.push(sum)
    }

    cont_op = cont_op.map(c => 100*c/20)

    return cont_op;
}