import React from 'react';
import {PropTypes} from "prop-types"
import Paper from "@material-ui/core/Paper"
import AddCircle from "@material-ui/icons/AddCircle"
import IconButton from "@material-ui/core/IconButton"
import {styles} from "./MatrixCell"
import PriorityPopover from "./PriorityPopover";


const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}


 const buildMatrix =()=>({
    entries: [...Array(6).keys()].map(
        _=>[...Array(5).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(5).keys()].map(_=>""),
})

class MatrixCellPriorityEditable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hovered:false, popoverOpen:false, popoverTarget:null};
        this.handlePriorityButtonClick = this.handlePriorityButtonClick.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
    }


    handlePopoverClose(priority, justification){
        if(priority !== null){
            this.props.setPriority(this.props.matrixId, this.props.row, this.props.col, priority);
            this.props.setJustification(this.props.matrixId, this.props.row, this.props.col, justification);
        }
        this.setState({
            popoverOpen:false,
        })
    }
    handlePriorityButtonClick(e){
        this.setState({popoverOpen:true, popoverTarget:e.target})
    }

    static getBackgroundColor(priority){
        return ["lightgreen", "yellow", "red"][priority];
    }

    render() {
        const highlight = this.props.highlight;
        return (<Paper style={{...styles.innerWrappers, border:"solid " + highlight.size + " " + highlight.color, backgroundColor:MatrixCellPriorityEditable.getBackgroundColor(this.props.priority)}}
                       onMouseEnter={_=>this.setState({hovered:true})}
                       onMouseLeave={_=>this.setState({hovered:false})}>
                <div>
                    <div>
                        {this.props.cell.text}
                    </div>
                    <div>
                        <IconButton  color={"primary"} onClick={this.handlePriorityButtonClick}>
                            <AddCircle style={{fontSize:"3vh"}}/>
                        </IconButton>
                    </div>
                </div>
            <PriorityPopover
                value={this.props.priority}
                justification={this.props.justification}
                open={this.state.popoverOpen}
                target={this.state.popoverTarget}
                heading={this.props.cell.text}
                extended={this.props.cell.extended}
                closeCallback={this.handlePopoverClose}/>
        </Paper>)
    }
}

MatrixCellPriorityEditable.propTypes = {
    row:PropTypes.number.isRequired,
    col:PropTypes.number.isRequired,
    priority: PropTypes.number,
    justification: PropTypes.string.isRequired,
    cell: PropTypes.object.isRequired,
    setPriority: PropTypes.func.isRequired,
    matrixId: PropTypes.number.isRequired,
    setJustification: PropTypes.func.isRequired,
};


const mapStateToProps = (state, ownProps) => {
    let matrix;
    if(ownProps.matrixId !== undefined && ownProps.matrixId !== null){
        matrix = state.practiceChanges.filter(v => v.id === ownProps.matrixId)[0].matrixData;
    }else{
        matrix = buildMatrix()
    }
    let entry = matrix.entries[ownProps.row][ownProps.col];
    return {
        label: state.matrixData.rows[ownProps.row].elements[ownProps.col],
        priority: entry.priority,
        justification: entry.justification
    }

};

const mapDispatchToProps = dispatch => ({
    // setPriority:(id, row, col, priority) => {
    //     dispatch(setPriority(id, row, col, priority))
    // },
    // setJustification:(id, row, col, just) => {
    //     dispatch(setJustification(id, row, col, just))
    // }
});

export default MatrixCellPriorityEditable;