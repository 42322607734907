import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IndicatorAccordian from "./IndicatorAccordian";
import {detail_ids, indicators} from "./ManagementActionPanel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {withStyles} from "@material-ui/core";


const SmallSelect = withStyles((theme) => ({
    input: {
        padding:"2px",
        fontSize:"0.9em",
    }
}))(OutlinedInput);

const example_actions = [
    {
        name: "Urban greening strategy",
        description: "Develop a collaborative urban greening strategy that identifies target areas for improving urban heat",
        cost:"20000",
        indicators: [
            [false, true, false, false, false, false, false],
            [false, false, false, false, false],
            [false, false, false, false],
            [false, false, false, false, false],
            [false, false, false, false],
            [true, true, false],
            [false, false, false, false, false, false,],
        ]
    },
    {
        name: "Water saving campaign",
        description: "Community campaign to educate people on using less water to reduce demand",
        cost:"50000",
        indicators: [
            [false, false, false, false, false, false, false],
            [true, true, false, false, false],
            [false, false, false, false],
            [false, false, true, false, false],
            [false, false, false, false],
            [false, false, false],
            [false, false, false, false, false, false,],
        ]
    }
];

class CityState extends Component {

    constructor(props) {
        super(props);
        let sq = props.linked_tdf_projects.filter(p => {
            return p.practice_changes.some(pc => {
                return props.city_tdf_data.some(ctd => ctd.tdf_issue_id === pc.id)
            })
        })
            // if sq is empty, then we have no projects to show
            let selectedProject = sq.length > 0 ? sq[0].id : null

            // .[0].id || null

        console.log("linked", props.linked_tdf_projects)
        this.state = {
            creatingAction: false,
            newActionName: "",
            newActionCost: "0",
            newActionDescription: "",
            selectedPracChange: [],
            selectedProject: selectedProject,
            selectedEnablingStrategies: [],
            selected_indicators: [
                [0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0],
                [0, 0, 0],
                [0, 0, 0, 0, 0, 0],
            ]
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };


    handleSelectEnablingStrategies = (id) => {
        let new_es;
        if(this.state.selectedEnablingStrategies.includes(id)){
            new_es = this.state.selectedEnablingStrategies.filter(v => v !== id);
        }else{
            new_es = [...this.state.selectedEnablingStrategies, id];
        }

        this.setState({selectedEnablingStrategies: new_es});
    }

    selectedIndicatorCallback = () => {
        this.props.selectedIndicatorCallback(this.state.selected_indicators);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.city_tdf_data !== this.props.city_tdf_data || prevProps.highlightedIssue !== this.props.highlightedIssue){

            this.setState({
                selectedProject: this.props.highlightedIssue !== null ?  this.props.linked_tdf_projects
                    .filter(p => {
                        return p.practice_changes.some(pc => {
                            return pc.id === this.props.highlightedIssue
                        })
                    })?.[0].id : null,
                selectedPracChange: this.props.highlightedIssue !== null ? [this.props.highlightedIssue] : [],
            })
        }
    }

    handleSelectIndicator = (major_index) => (minor_index) => {
        this.setState({
            selected_indicators: this.state.selected_indicators.map((v,i) => {
                return v.map((s,j) => {
                    if(i === major_index && j === minor_index){
                        return !s
                    }
                    return s;
                })
            })
        })
        this.props.selectedIndicatorCallback(this.state.selected_indicators.map((v,i) => {
            return v.map((s,j) => {
                if(i === major_index && j === minor_index){
                    return !s
                }
                return s;
            })
        }))
    }
    renderIndicatorContent = (summary, detail_list, index) => {
        return <IndicatorAccordian
            selectable={true}
            selectedIndicators={this.state.selected_indicators[index]}
            onIndicatorSelect={this.handleSelectIndicator(index)}
            highlightList={this.props.highlightedIndicators}
            relative={this.props.target_index_data !== undefined && this.props.target_index_data !== null}
            comparison_data={this.props.target_index_data && this.props.target_index_data[index]}
            editable={false}
            ratingBaseValues={this.props.city_index_data[index]}
            key={index}
            summary={summary}
            detailList={detail_list}/>
    }

    handleSubmitAction = () => {
        this.props.submitActionCallback(this.state.newActionName, this.state.newActionDescription, this.state.newActionCost, this.state.selectedEnablingStrategies)
        this.selectedIndicatorCallback()
        this.setState({creatingAction: false})
    }

    filterActions = (b) => {
        return b.mapped_outputs.every((major,i) => {
            return major.every((minor,j) => {
                return minor ||  minor === !!this.state.selected_indicators[i][j];
            })
        });
    }

    render() {

        console.log("hgihlightedes", this.state.highlightedEs)
        console.log("selectedPracChange", this.state.selectedPracChange)


        const prac_changes = this.props.linked_tdf_projects.filter(p => p.id === this.state.selectedProject)
            .flatMap(proj => proj.practice_changes).filter(pc => this.state.selectedPracChange.length === 0 || this.state.selectedPracChange.includes(pc.id))

        return <div style={{width:"100%", height:"100%"}}>
            <Dialog open={this.state.creatingAction} maxWidth={"xl"} fullWidth>
                <DialogTitle>
                    Add New Action
                </DialogTitle>
                <DialogContent dividers style={{height:"70vh", display:"flex", padding:"0px"}}>
                    <div style={{flex:1, padding:"5px"}}>
                        <Typography>Suggested Management Actions</Typography>
                        <List>
                            {
                                (this.props.management_action_database.filter(this.filterActions).map((f,i) => {
                                    return <ListItem dense button onClick={_=>this.setState({newActionName: f.name, newActionDescription: f.desc, newActionCost: f.cost})}>
                                        <ListItemText>
                                            {f.name}
                                        </ListItemText>
                                    </ListItem>
                                }))
                            }
                        </List>
                    </div>
                    <Divider orientation={"vertical"}/>
                    <div style={{flex:4, padding:"10px", display:"flex", flexDirection:"column"}}>
                        <div style={{marginBottom:"10px"}}>
                            <TextField
                                value={this.state.newActionName}
                                onChange={_=>this.setState({newActionName: _.target.value})}
                                label={"Name"}
                                fullWidth
                                variant={"outlined"}
                                size={"small"} />
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <TextField
                                value={this.state.newActionDescription}
                                onChange={_=>this.setState({newActionDescription: _.target.value})}
                                multiline
                                rows={4}
                                label={"Short Description"}
                                fullWidth
                                variant={"outlined"}
                                size={"small"} />
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <TextField
                                value={this.state.newActionCost}
                                onChange={_=>{
                                    if(!(/^[+-]?([0-9]*[.])?[0-9]*$/.test(_.target.value))){
                                        return;
                                    }
                                    this.setState({newActionCost: _.target.value})}
                                }
                                label={"Cost"}
                                fullWidth
                                variant={"outlined"}
                                size={"small"} />
                        </div>

                        <div>
                            <br/>
                            Note: For the detailed setup of the Action please see the Management Actions panel
                            <br/>
                        </div>
                        <div>
                            <br/>
                        </div>
                        <div style={{padding: "10px"}}>
                            Enabling Strategies
                        </div>

                        <div style={{flex:1, display:"flex"}}>
                            <div style={{flex:1, display:"flex", flexDirection:"column"}}>
                                <div>
                                    City Filter:
                                    <Select
                                        variant={"outlined"}
                                        input={<SmallSelect />}
                                        style={{marginRight:"10px"}} value={this.state.selectedProject} onChange={(e) => this.setState({selectedProject: parseInt(e.target.value), selectedPracChange: []})}>

                                        <MenuItem value={null}>none</MenuItem>
                                        {
                                            this.props.linked_tdf_projects
                                            .map(p => {
                                                return <MenuItem value={p.id}>{p.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    Issue Filter:
                                    <Select
                                        multiple
                                        input={<SmallSelect/>}
                                        variant={"outlined"}  value={this.state.selectedPracChange} onChange={(e) => this.setState({selectedPracChange:e.target.value.includes(null) ? [] : e.target.value.map(parseInt),})}>

                                        <MenuItem value={null}>none</MenuItem>
                                        {
                                            this.props.linked_tdf_projects.find(proj => proj.id === this.state.selectedProject)?.practice_changes
                                            .map(p => {
                                                return <MenuItem value={p.id}>{p.issue_name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </div>
                                <div style={{position:"relative", flex:1}}>
                                    <div style={{position:"absolute", top:0, right:0, left:0, bottom:0, overflow:"hidden"}}>
                                        <div style={{overflowY:"auto", height:"100%"}}>
                                            <List dense>
                                                {
                                                    this.props.linked_tdf_projects.filter(p => p.id === this.state.selectedProject)
                                                        .flatMap(proj => proj.enabling_strategies)
                                                        .filter(es => undefined !== prac_changes.find(pc => es.practice_change_id === pc.id))
                                                        .map(es => {
                                                        return <ListItem dense button onClick={_=>this.handleSelectEnablingStrategies(es.id)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    size={"small"}
                                                                    edge="start"
                                                                    checked={this.state.selectedEnablingStrategies.includes(es.id)}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                {es.text}
                                                            </ListItemText>
                                                        </ListItem>
                                                    })
                                                }
                                            </List>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color={"secondary"} variant={"outlined"} onClick={_=>this.setState({creatingAction: false})}>
                        cancel
                    </Button>

                    <Button color={"primary"} variant={"outlined"} onClick={this.handleSubmitAction}>
                        submit
                    </Button>
                </DialogActions>
            </Dialog>
                <div style={{display:"flex"}}>
                    {this.renderIndicatorContent(indicators[0].summary, indicators[0].details, 0)}
                    {this.renderIndicatorContent(indicators[1].summary, indicators[1].details, 1)}
                </div>
                <div style={{display:"flex"}}>
                    {this.renderIndicatorContent(indicators[2].summary, indicators[2].details, 2)}
                    {this.renderIndicatorContent(indicators[3].summary, indicators[3].details, 3)}

                </div>
                <div style={{display:"flex"}}>
                    {this.renderIndicatorContent(indicators[4].summary, indicators[4].details, 4)}
                    {this.renderIndicatorContent(indicators[5].summary, indicators[5].details, 5)}

                </div>
                <div style={{display:"flex"}}>
                    {this.renderIndicatorContent(indicators[6].summary, indicators[6].details, 6)}
                    <div style={{flex:1, display:"flex", alignItems:"center"}}>
                        <div style={{flex:1}}/>
                        <Button  variant={"outlined"} color={"primary"} size={"small"} onClick={_=>this.setState({creatingAction: true})}>
                            Create Action
                        </Button>
                        <div style={{flex:1}}/>
                    </div>
                </div>
        </div>;
    }
}


CityState.propTypes = {
    city_index_data: PropTypes.any,
    target_index_data: PropTypes.any,
    linked_tdf_projects: PropTypes.any,
    management_action_database: PropTypes.any
}


export default CityState;
