import React from 'react';
import {PropTypes} from "prop-types";
import Paper from "@material-ui/core/Paper/Paper";
import {styles} from "./MatrixCell"
import Tooltip from "@material-ui/core/Tooltip"


const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}


const buildMatrix =()=>({
    entries: [...Array(6).keys()].map(
        _=>[...Array(5).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(5).keys()].map(_=>""),
})

class MatrixCellHeading extends React.Component {

    constructor(props) {
        super(props);
    }


    static getBackgroundColor(priority) {
        return ["lightgreen", "yellow", "red"][priority];
    }


    render() {
        const highlight = this.props.highlight;
        return (<Paper style={{...styles.innerWrappers}}>
            {this.props.cell === undefined || this.props.col === -1 ?
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                    {(this.props.cell === undefined ? "": (this.props.row === -1) ? this.props.cell.text : this.props.cell.label)}
                </div>
                :
                <Tooltip title={this.props.cell.extended}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                            {(this.props.cell === undefined ? "": (this.props.row === -1) ? this.props.cell.text : this.props.cell.label)}
                    </div>
                </Tooltip>}
        </Paper>)
    }
}

MatrixCellHeading.propTypes = {
    row: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    priority: PropTypes.number,
    matrixId: PropTypes.number.isRequired,
    cell: PropTypes.object.isRequired,
};



export default MatrixCellHeading;