export default  {
    heading_row: [
        {text: "Transition phase", extended: ""},
        {text: "Champions", extended: "Key networks of individuals and organisations"},
        {text: "Platforms for connecting", extended: "(Semi) Formalised organisations, structures, & processes for coordination and alignment"},
        {text: "Knowledge", extended: "Research, science & contextualised knowledge"},
        {text: "Projects and applications", extended: "Experiments, demonstrations, & focus projects"},
        {text: "Tools and instruments", extended: "Legislative, policy, regulative, & practice tools"}
    ],
    rows: [
        {
            label: "1. Issue emergence",
            elements: [
                {text: "Issue activists", extended: "Issue activists (from community or industry) champion the issue"},
                null,
                {text: "Issue highlighted", extended: "Science or local experience identifies there is an issue"},
                {text: "Issue examined", extended: "Studies (conducted locally or elsewhere) exist that have high profile in the local context"},
                null,
            ]
        },
        {
            label: "2. Issue definition",
            elements: [
                {text: "Individual champions", extended: "Individual champions (from science, industry or community) define the issue from their own perspective, providing greater clarity about why it’s important"},
                {text: "Sharing concerns and ideas", extended: "Early connections between champions emerge, through which the need for better coordination to profile the issue is identified\n"},
                {text: "Causes and impacts examined", extended: "Science explains the specific causes and impacts of the issue"},
                {text: "Solutions explored", extended: "Solution options are experimented with"},
                null,
            ]
        },
        {
            label: "3. Shared understanding and issue agreement",
            elements: [
                {text: 'Connected champions', extended: "Champions connect with each other to propose ideas for specific solutions and initiate solution development that address the issue"},
                {text: 'Developing a collective voice', extended: "Mechanisms exist that aim to develop a collective voice that speaks with clarity about the issue and advocates potential solutions"},
                {text: 'Solutions developed', extended: "Collaboration across science and industry develops basic solutions"},
                {text: 'Solutions experimented with', extended: "Solutions are tested in the field and evaluated for performance, feasibility and community acceptance"},
                {text: 'Preliminary practical guidance', extended: "Preliminary guidelines for practice application exist"}
            ]
        },
        {
            label: "4. Knowledge dissemination",
            elements: [
                {text: 'Influential champions', extended: "Champions have a common position and are engaging with policy actors to advance and make way for solutions to be reflected in policy and practice"},
                {text: 'Building broad support:', extended: "Mechanisms exist that aim to broadcast consistent messages to build broad support and develop pathways for implementing solutions"},
                {text: 'Solutions advanced', extended: "Collaboration across science and industry advances the solutions through validation, refinement and generalisation"},
                {text: 'Solutions demonstrated at scale', extended: "Significant solution demonstrations exist that include a learning agenda"},
                {text: 'Refined guidance and early policy', extended: "Refined guidelines exist that link the practice to a broader policy agenda, are recognised in policy and early regulation either (a) at the state/city level, or (b) by organisations individually or collectively evolving their own policies"}
            ]
        },
        {
            label: "5. Policy and practice diffusion",
            elements: [
                {text: 'Organisational champions', extended: "Organisational champions lead multi-stakeholder networks in becoming aligned and starting to embed the solutions in policy and practice "},
                {text: 'Expanding the community of practice', extended: "Mechanisms exist that aim to expand the community of practice focused on implementing solutions to address the issue and building stakeholder support for the solutions"},
                {text: 'Capacity building', extended: "Solutions are translated into simplified packages for broad application with accompanying capacity building activities"},
                {text: 'Widespread implementation and learning', extended: "Widespread industry-led adoption of new practices leads to organisational learning and adaptation"},
                {text: 'Early regulation and targets', extended: "Practice undergoes refinement and adaptation across industry and government to streamline processes and improve outcomes"}
            ]
        },
        {
            label: "6. Embedding new practice",
            elements: [
                {text: 'Multi-stakeholder networks', extended: "Stakeholders are implementing practices that are aligned across sectors to deliver the solutions"},
                {text: 'Guiding consistent application', extended: "Mechanisms exist that aim to develop and embed the systems and processes that enable ongoing and consistent implementation of the practice across the sector"},
                {text: 'Monitoring and evaluation', extended: "Science focuses on reflection, monitoring, evaluation; new questions and directions emerge"},
                {text: 'Standardisation and refinement', extended: "Implementation is being standardised and refined"},
                {text: 'Comprehensive policy and regulation', extended: "A comprehensive policy and regulatory framework (e.g. legislation, regulatory standards, oversight, measurement, reporting, accountability) begins to achieve consistent sector-wide governance and application of the practice"}
            ]
        }
    ]
}


const generateEntry = function(v){
    return {
        justification: "",
        priority: v,
    }
}

export const generate_matrix = () => {
    let cols = [0, 0, 0, 0, 0]
    return {
        entries: [...Array(6).keys()].map(
            (_,k)=>{

                return [...Array(5).keys()].map((_,i)=>{
                    if(Math.random() > 0.7){
                        cols[i] = Math.min(2, cols[i]+1)
                    }
                    return generateEntry(cols[i])
                })
            }
        ),
        suggestedScore:0,
        overrideScore:0,
        columnJustifications: [...Array(5).keys()].map(_=>""),
    }
}