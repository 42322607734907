import React from 'react';
import "mapbox-gl/dist/mapbox-gl.css";
import Root from "./Root";
function App() {
  return (
        <Root/>
  );
}

export default App;
