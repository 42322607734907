/**
 * Created by User on 19/10/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, withRouter} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AddIcon from "@material-ui/icons/Add"
import ListItemText from "@material-ui/core/ListItemText";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ReactPlaceholder from "react-placeholder";
import {Cell, Legend, RadialBar, RadialBarChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import IndexPreview from "../views/IndexPreview";
import TDFPreview from "../views/TDFPreview";
import ManagementActionsPreview from "../views/ManagementActionsPreview";
import ManagementActionPanel, {detail_ids} from "../views/ManagementActionPanel";
import ImpactPanel from "../views/ImpactPanel";
import DataLoader from "../DataLoader";
import API, {combined, test} from "../API";
import Select from "@material-ui/core/Select";
import CityState from "../views/CityState";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import StrategyPanel from "../views/StrategyPanel";



class CityScreen extends Component {

    constructor(props) {
        super(props);
        this.ma_accordion_ref = React.createRef();
        this.scrollRef = React.createRef();
        this.state = {
            selected: [...new Array(20)].map(_=>false),
            selected2: [...new Array(20)].map(_=>false),
            selected_actions: [],
            currentTab: 0,
            highlightedIssue: null,
            highlightedIndicators: [],
            createdActionID: null,
            selectedIndicators: null,
            selectedStrategy: null,
            addingAction: false,
            selectedTarget: "",
            selectedAction: null,
            accordions: [true, false, false, false],
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    renderImpactPanel = () => {
        return <Paper variant={"outlined"} style={{
            flex: 1,
            height:"80vh",
            margin: "10px",
            display: "flex",
            flexDirection: "row"
        }}>
            <div style={{flex:2, overflowY:"auto"}}>
                <div style={{position:"sticky", boxSizing:"border-box", padding:"10px", top:0, backgroundColor:"white", zIndex:1201}}>Management Actions</div>
                <List>
                    {this.props.data.actions.map((action, i) =>
                        <ListItem button dense onClick={_=>this.setState({
                            selected_actions: !this.state.selected_actions.includes(action.id)  ?
                                [...this.state.selected_actions, action.id] : this.state.selected_actions.filter(a => a !== action.id)
                        })}>
                            <ListItemIcon>
                                <Checkbox
                                    size={"small"}
                                    edge="start"
                                    checked={this.state.selected_actions.includes(action.id)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText>{action.name}</ListItemText>
                        </ListItem>)}
                </List>
            </div>
            <Divider orientation={"vertical"}/>
            <div style={{flex:7}}>
                <ImpactPanel
                    submitStrategyCallback={this.handleSubmitStrategy}
                    targetRatings={this.props.data.target_indicies.find(f => f.project.id === parseInt(this.state.selectedTarget))?.ratings}
                    tdfProjects={this.props.linked_tdf_projects}
                    baseRatings={this.props.data.city_detail_data}
                    managementActions={this.props.data.actions.filter((f,i) => this.state.selected_actions.includes(f.id))}/>
            </div>
        </Paper>
    }

    renderStrategyPanel = () => {

        let strategy = this.props.data.strategies.find(s => {
            return s.id === this.state.selectedStrategy
        });
        if(strategy !== undefined) {
            strategy = {...strategy};
            strategy.actions = strategy.actions.map(s => {
                return this.props.data.actions.find(a => s.id === a.id);
            })
        }

        let enabling_strategies = [];
        let practice_changes = [];
        let _ = this.props.data.city_tdf_data?.map(f => {
            this.props.linked_tdf_projects.map(tdfp => {
                let t = tdfp.enabling_strategies.filter(es => {
                    return true //es.practice_change_id === f.tdf_issue_id; // disable filtering for particular issues
                });
                let b = tdfp.practice_changes.find(pc => {
                    return true //pc.id === f.tdf_issue_id;
                });
                if(b) {
                    practice_changes.push({project: tdfp, issue: b});
                }
                let tm = t.map(es => [es,tdfp.practice_changes.find(a => a.id === es.practice_change_id)])
                enabling_strategies.push(...tm.map(t => ({project:tdfp, issue:t[1], enabling_strategy: t[0]})));
            })
        })

        return <Paper variant={"outlined"} style={{
            flex: 1,
            height:"80vh",
            margin: "10px",
            display: "flex",
            flexDirection: "row"
        }}>
            <div style={{flex:1, padding:"20px"}}>
                Action Groups
                <List>
                    {this.props.data.strategies.map((strategy, i) =>
                        <ListItem button dense
                              selected={this.state.selectedStrategy === strategy.id}
                              onClick={_=>this.setState({selectedStrategy: strategy.id})}
                        >
                            <ListItemText>{strategy.strategy_name}</ListItemText>
                        </ListItem>)}
                </List>
            </div>
            <Divider orientation={"vertical"}/>
            <div style={{flex:4}}>
                <StrategyPanel
                    enablingStrategies={enabling_strategies}
                    baseRatings={this.props.data.city_detail_data}
                    targetRatings={this.props.data.target_indicies.find(f => f.project.id === parseInt(this.state.selectedTarget))?.ratings}
                    deleteCallback={this.handleDeleteStrategy}
                    tdfProjects={this.props.linked_tdf_projects}
                    strategy={strategy}
                />
            </div>
        </Paper>
    }

    renderManagementActionPanel = () => {
        return <Paper variant={"outlined"} style={{
                flex: 1,
                margin: "10px",
                display: "flex",
                height:"1000px",
                flexDirection: "column"
            }}>
                <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                    <div style={{flex: 1, padding:"10px", overflowY:"auto", maxHeight:"950px",}}>
                        <List dense>
                            {
                                this.props.data.actions.map(a => {
                                    return <ListItem
                                        selected={this.state.selectedAction === a.id}
                                        dense
                                        button
                                        onClick={_=>this.setState({selectedAction: a.id, createdActionID: null})}>
                                        <ListItemText>
                                            {a.name}
                                        </ListItemText>
                                    </ListItem>
                                })
                            }
                        </List>
                    </div>

                    <Divider orientation={"vertical"}/>
                    <div style={{flex:4}}>
                        <ManagementActionPanel
                            indicatorFilter={this.state.createdActionID === this.state.selectedAction ? this.state.selectedIndicators : null}
                            deleteCallback={this.handleDelete}
                            handleSaveCallback={this.handleSave}
                            linkEnablingStrategyCallback={this.linkEnablingStrategyToAction}
                            linked_tdf_projects={this.props.linked_tdf_projects}
                            city_tdf_data={this.props.data.city_tdf_data}
                            city_detail_data={this.props.data.city_detail_data}
                            city_index_data={this.props.data.city_index_data}
                            managementAction={this.props.data.actions.find(f => this.state.selectedAction === f.id)}/>
                    </div>
                </div>
            </Paper>
    }

    handleSave = (action_id, name, description, detailed_desc, justification, cost, ratings) => {
        API.action.post(action_id, this.props.project.id, this.props.match.params.city_id, {
            name: name,
            description: description,
            detailed_desc: detailed_desc,
            justification: justification,
            cost:cost,
            ratings: ratings
        }).then(f => {
            this.props.refresh_data();
        });
    }

    handleDelete = (action_id) => {
        API.action.delete(action_id, this.props.project.id, this.props.match.params.city_id).then(f => {
            this.props.refresh_data();
        });
    }

    handleDeleteStrategy = (strategy_id) => {
        API.strategy.delete(strategy_id, this.props.project.id, this.props.match.params.city_id).then(f => {
            this.props.refresh_data();
        });
    }

    handleAddIssue = (issue_id) => {
        API.cityTDF.post(this.props.project.id, this.props.match.params.city_id, issue_id).then(f => {
            this.props.refresh_data();
        })
    }

    linkEnablingStrategyToAction = (action_id, strategy_ids) => {
        API.actionEnablingStrategies.post(action_id, this.props.project.id, this.props.match.params.city_id, strategy_ids).then(f => {
            this.props.refresh_data();
        })
    }

    handleSubmitStrategy = (name, description) => {
        API.strategy.post(this.props.project.id, this.props.match.params.city_id, this.props.match.params.ma_project_id,{
            name: name,
            description: description,
            actions:this.state.selected_actions
        }).then(f => {
            this.props.refresh_data();
        })
    }


    highlightIssue = (id) => {
        /*
            this is so gross i hope we never have to
            change this but its basically a conversion
            from tdf indicators to index indicators
         */
        if(this.state.highlightedIssue === id){
            this.setState({
                highlightedIssue: null,
                highlightedIndicators: [],
            })
            return;
        }
        let highlightedIndicators = [];
        let prac_change = this.props.linked_tdf_projects.find(f => {
            return f.practice_changes.find(p => p.id === id) !== undefined;
        }).practice_changes.find(p => p.id === id);

        let major_index = 0;
        let acc_length = 0;
        for(let i = 0; i < prac_change.indicators.length; i++){
            let next = prac_change.indicators[i] - 1;
            while((next - acc_length) >= detail_ids[major_index].length){
                acc_length += detail_ids[major_index].length
                major_index += 1;
            }
            let id = detail_ids[major_index][next - acc_length];
            highlightedIndicators.push(id);
        }

        this.setState({
            highlightedIssue: id,
            highlightedIndicators: highlightedIndicators
        })
    }

    handleSubmitAction = (name, description, cost, enabling_strategies) => {
        this.setState({
            accordions: this.state.accordions.map((_,i) => i===2?true:_),
        })
        API.createAction.post(this.props.project.id, this.props.match.params.city_id, this.props.match.params.ma_project_id,{
            name: name,
            description: description,
            cost: cost,
        }).then(_ => {
            return API.actionEnablingStrategies.post(_.id, this.props.project.id, this.props.match.params.city_id, enabling_strategies).then(r => _);
        }).then(_=>{
            this.setState({
                selectedAction: _.id,
                createdActionID: _.id,
            })
            return this.props.refresh_data();
        }).then(_=>{
            this.scrollRef.current.scrollTo(0, this.ma_accordion_ref.current.offsetTop)
        })
    }

    renderSection = (index, heading,content, ref=null, transition_end_callback = null) => {
        let extra_props = {};
        if(ref !== null){
            extra_props["ref"] = ref;
        }
        if(transition_end_callback !== null){
            extra_props["TransitionProps"] = {
                onEnter: transition_end_callback
            }
        }
        return <Accordion

                onChange={_=>this.setState({accordions:this.state.accordions.map((_,i) => i===index? !_:_)})}
                expanded={this.state.accordions[index]}
                variant={"outlined"}
                style={{margin:"10px"}}
                {...extra_props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{heading}</AccordionSummary>
            <Divider/>
            <AccordionDetails style={{padding:"4px"}}>
                {content}
            </AccordionDetails>
        </Accordion>
    }


    setSelectedIndicators = (indicators) => {
        this.setState({
            selectedIndicators: indicators,
        })
    }

    render() {
        if(this.props.loading){
            return <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", width:"100%", height:"100%"}}>
                <CircularProgress variant={"indeterminate"} size={100}/>
                loading
            </div>
        }
        const project = this.props.project;
        const city = this.props.cities.find(c => c.id === parseInt(this.props.match.params.city_id));
        return <div style={{boxSizing:"border-box", height:"100%", display:"flex", flexDirection:"column"}}>
                <Dialog open={this.state.addingAction} maxWidth={"lg"} fullWidth>
                    <DialogTitle>
                        Adding Management Action
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{minHeight:"50vh", display:"flex"}}>
                            <div style={{flex: 1, position:"relative", overflow:"hidden"}}>
                                <div style={{position:"absolute", overflowY:"auto", top:0, left:0, right:0, bottom:0}}>
                                    <div style={{backgroundColor:"white", position:"sticky", top:0, zIndex:10}}>
                                        Indicators
                                    </div>
                                    <List dense>
                                        {[...new Array(20)].map((value, i) =>
                                            <ListItem button dense onClick={_=>this.setState({selected:this.state.selected.map((_,i2)=>i===i2?!_:_)})}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        size={"small"}
                                                        edge="start"
                                                        checked={this.state.selected[i]}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText>Indicator {i}</ListItemText>
                                            </ListItem>)}

                                    </List>
                                </div>
                            </div>
                            <div style={{flex: 1, position:"relative", overflow:"hidden"}}>
                                <div style={{position:"absolute", overflowY:"auto", top:0, left:0, right:0, bottom:0}}>
                                    <div style={{backgroundColor:"white", position:"sticky", top:0, zIndex:10}}>
                                        TDF Issues
                                    </div>
                                    <List dense>
                                        {[...new Array(20)].map((value, i) =>
                                            <ListItem button dense onClick={_=>this.setState({selected2:this.state.selected2.map((_,i2)=>i===i2?!_:_)})}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        size={"small"}
                                                        edge="start"
                                                        checked={this.state.selected2[i]}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText>TDF Issue {i}</ListItemText>
                                            </ListItem>)}

                                    </List>
                                </div>
                            </div>
                            <div style={{flex: 2, padding:"20px"}}>
                                <div>
                                    Template 1:
                                    <ReactPlaceholder type={"media"} rows={2}/>
                                    <br/>
                                    Template 2:
                                    <ReactPlaceholder type={"media"} rows={4}/>
                                    <br/>
                                    Template 3:
                                    <ReactPlaceholder type={"media"} rows={5}/>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button>
                            submit
                        </Button>
                        <Button onClick={_=>this.setState({addingAction:false})}>
                            cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Paper variant={"outlined"} style={{display:"flex", flexDirection:"column", height:"100%", margin:"10px", flex:1}}>
                    <div style={{margin:"10px"}}>
                        {city.city_name} ({project.name})
                    </div>
                    <Divider/>
                        <div style={{display: "flex", position:"relative", flex: 1, flexDirection: "column", backgroundColor:"#f3f3f3"}}>
                            <div style={{position:"absolute", inset:0, overflowY:"auto"}} ref={this.scrollRef}>
                                <Paper variant={"outlined"} style={{margin: "10px", padding: "10px"}}>
                                    <div>
                                        Target
                                        <Select
                                            value={this.state.selectedTarget}
                                            onChange={_=>this.setState({
                                                selectedTarget: _.target.value
                                            })}
                                            inputProps={{
                                                style:{
                                                    textAlign:"center",
                                                    fontSize:"0.9em",
                                                    paddingTop:"2px",
                                                    paddingBottom:"2px"
                                                }
                                            }}
                                            variant={"outlined"}
                                            style={{marginLeft:"10px"}}
                                            native
                                        >
                                            <option value={""}>none</option>
                                            {
                                                this.props.data.target_indicies.map(t => {
                                                    return <option value={t.project.id}>
                                                        {t.project.name}
                                                    </option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </Paper>
                                {this.renderSection( 0,"Analysis",
                                    <div style={{display: "flex", width:"100%", height: "600px", flexDirection: "row"}}>

                                        <Paper variant={"outlined"} style={{flex: "1", flexDirection:"column", display:"flex", padding: "10px"}}>
                                            <div>Index</div>
                                                <IndexPreview
                                                    target={this.props.data.target_indicies.find(f => f.project.id === parseInt(this.state.selectedTarget))}
                                                    data={this.props.data.index_data}
                                                    radar_data={this.props.data.city_index_data}/>
                                        </Paper>
                                        <div style={{width:"10px"}}/>
                                        <Paper variant={"outlined"} style={{
                                            flex: "1",
                                            padding: "10px",
                                            boxSizing: "border-box",
                                            overflowY: "hidden"
                                        }}>
                                            <div style={{width: "100%", height: "100%", position: "relative"}}>
                                                <TDFPreview
                                                    selectedIndicators={this.state.selectedIndicators}
                                                    highlightedIssues={this.state.highlightedIssue}
                                                    highlightIssueCallback={this.highlightIssue}
                                                    addIssueCallback={this.handleAddIssue}
                                                    linked_tdf_projects={this.props.linked_tdf_projects}
                                                    city_tdf_data={this.props.data.city_tdf_data}
                                                />
                                            </div>
                                        </Paper>
                                    </div>
                                )}
                                {this.renderSection(1,"WSC Index Goals and Indicators",

                                        <CityState
                                            selectedIndicatorCallback={this.setSelectedIndicators}
                                            submitActionCallback={this.handleSubmitAction}
                                            highlightedIndicators={this.state.highlightedIndicators}
                                            highlightedIssue={this.state.highlightedIssue}
                                            linked_tdf_projects={this.props.linked_tdf_projects}
                                            city_tdf_data={this.props.data.city_tdf_data}
                                            target_index_data={this.props.data.target_indicies.find(f => f.project.id === parseInt(this.state.selectedTarget))?.ratings}
                                            city_index_data={this.props.data.city_detail_data}
                                            management_action_database={this.props.data.management_action_database}/>

                                )}
                                {this.renderSection(2,"Management Actions",
                                    this.renderManagementActionPanel(),
                                    this.ma_accordion_ref
                                )}
                                {this.renderSection(3,"Model Impact",
                                    this.renderImpactPanel()
                                )}
                                {this.renderSection(4,"Management Actions Groups",
                                    this.renderStrategyPanel()
                                )}
                            </div>
                        </div>


                </Paper>
        </div>;
    }
}


CityScreen.propTypes = {
    project: PropTypes.object,
    city: PropTypes.object,
}


export default withRouter(DataLoader(CityScreen, props => {
    return combined({
        target_indicies: _=>API.targetIndexData.get(),
        index_data:_=>API.indexData.get(props.project.id,props.match.params.city_id),
        city_index_data:_=>API.cityIndex.get(props.project.id,props.match.params.city_id),
        city_detail_data:_=>API.cityDetail.get(props.project.id,props.match.params.city_id),
        city_tdf_data:_=>API.cityTDF.get(props.project.id,props.match.params.city_id),
        strategies:_=>API.strategy.get(props.project.id,props.match.params.city_id,props.match.params.ma_project_id),
        actions:_=>API.action.get(props.project.id,props.match.params.city_id,props.match.params.ma_project_id),
        management_action_database:_=>API.actionDatabase.get()
    })
}, true));
