import React from 'react';
import {PropTypes} from "prop-types"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
class JustificationPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            text:props.justification
        }
    }


    componentDidUpdate(prevProps){
        if(this.props.open === true && prevProps.open === false){
            this.setState({
                text:this.props.justification
            })
        }
    }


    render() {
        return "";
        return (
            <Dialog maxWidth={"md"} fullWidth open={this.props.open} onClose={_=>this.props.closeCallback(this.state.text)} >
                <DialogTitle>Justifications for {this.props.colTitle.text}</DialogTitle>
                <DialogContent>
                    <div>
                        {this.props.columnJustifications.map((v, i) => !v || v.length === 0 ? "" : <div> <div style={{color:"#999"}}>{this.props.matrixData.rows[i].label}:</div> <div style={{whiteSpace:"pre-wrap"}}>{v}</div></div>)}
                    </div>
                    <div style={{color:"#999"}}>
                        Overall:
                    </div>
                    <TextField
                        defaultValue={this.state.text}
                        name={"justifications"}
                        placeholder={this.props.readOnly ? "empty":  "type justifications in here"} fullWidth multiline rows={10} disabled={this.props.readOnly} onChange={e=>this.setState({text:e.target.value})}/>
                </DialogContent>
                <DialogActions>
                    <Button color={"secondary"} variant={"outlined"} onClick={_=>this.props.closeCallback(null)}>cancel</Button>
                    {this.props.readOnly ? "" : <Button color={"primary"} variant={"contained"} onClick={_=>this.props.closeCallback(this.state.text)}>save</Button>}
                </DialogActions>
            </Dialog>
        )
    }
}

JustificationPopover.propTypes = {
    open: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    colTitle: PropTypes.object.isRequired,
    columnJustifications: PropTypes.array.isRequired,
    closeCallback:PropTypes.func.isRequired,
    justification: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {
    return {
        matrixData: state.matrixData
    }
}

const mapDispatchToProps = dispatch => ({
})

export default JustificationPopover;