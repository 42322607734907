import React, {Component} from 'react';
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProjectCard from "./ProjectCard";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Person} from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import {MenuItem} from "@material-ui/core";

class CityPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cities:{},
            selected_city_name: "",
            addProject: false,
            city_id : -1,
            maProjects : [],
            tdfProject: null
        };

    }

    componentDidMount() {

        const marker_map = {};

        this.props.cities.map(m => {
            marker_map[m.id] = m
        })

        this.setState({cities: marker_map})


    };

    componentWillUnmount() {
    };

    handleDelete = (id) => {
        this.props.deleteProjectCallback(id)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.highlight !== prevProps.highlight){
            Object.keys(this.state.cities).map(i => {
                if(parseInt(i) === parseInt(this.props.highlight)) {
                    this.setState({selected_city_name: this.state.cities[i].name})
                    this.setState({city_id: this.state.cities[i].id})
                }
            })
        }
    }

    handleAddProject = (city_id) => {
        this.props.addProjectCallback(city_id, this.state.project_name, this.state.tdfProject);



        this.setState({addProject: false});
    }

    handleTextChange = (key) => e => {
        let newstate = {};
        newstate[key] = e.target.value;
        this.setState(newstate);
    }
    handleSelectedTDFProject = (e) => {
        this.setState( {tdfProject: e.target.value});
    }

    render()
    {

        let tdf_link  = "https://www.enablingtransitions.org"
        return <Container style={{flex:"1"}} maxWidth={"lg"}>

            <Dialog maxWidth={"lg"} fullWidth open={this.state.addProject}>
                <DialogTitle>New Project</DialogTitle>

                <DialogContent>
                    Select TDF project
                    <Select
                        value={this.state.tdfProject}
                        onChange={this.handleSelectedTDFProject}>
                        {this.props.linkedTDFProjects.filter(t => t.city_id === this.state.city_id).map(linked => {
                            return <MenuItem
                                value={linked.tdf_project_id}>{this.props.tdfProjects.find(proj => proj.id === linked.tdf_project_id)?.name}
                            </MenuItem >
                        })}
                    </Select>

                </DialogContent>
                <DialogActions>
                    <TextField style={{margin:"2% 2%",width:"95%"}} variant={"outlined"}
                               label={"Assessment Name"}
                               InputLabelProps={{shrink:true}}
                               onChange={this.handleTextChange("project_name")}
                               fullWidth/>

                    <Button size={"small"} variant={"outlined"} color={"secondary"} onClick={_=>this.setState({addProject: false})}>
                        cancel
                    </Button>

                    <Button  size={"small"} variant={"outlined"} color={"primary"}
                             onClick={_=>this.handleAddProject(this.state.city_id)}>
                        confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <div style={{paddingTop:"10px"}}>

                <div style={{fontSize:"1.2em", paddingBottom:"10px"}}>
                    {this.state.selected_city_name}
                </div>

                <div style={{fontSize:"1.2em", paddingBottom:"10px"}}>
                    Index assessments
                </div>
                <div>
                        <a target="_blank" href="http://index.watersensitivecities.org.au/interactive_index/">Latest Index Assessment</a>
                </div>

                <div style={{fontSize:"1.2em", paddingBottom:"10px"}}>
                    TDF assessments
                </div>
                <div>
                    {/*<a target="_blank" href={tdf_link}>Latest TDF Assessment</a>*/}
                    {this.props.linkedTDFProjects.filter(t => t.city_id === this.state.city_id).map(linked => {
                        return <ListItem dense button>
                            <ListItemText>
                                {

                                    "Linked TDF assessment: " + this.props.tdfProjects.find(proj => proj.id === linked.tdf_project_id)?.name

                                }
                            </ListItemText>
                        </ListItem>
                    })}
                    {/*<> {this.props.tdf_projects.find(proj => proj.id === linked.tdf_project_id)?.name}</>*/}
                </div>

                <Paper style={{height:"100%", padding:"10px", boxSizing:"border-box"}}>
                    <Typography variant={"h6"}>
                       Management Actions
                    </Typography>
                    <List dense>
                        {this.props.maProjects.filter(maProject => maProject.city_id === this.state.city_id).map(maProject => {
                            return <ListItem dense button>
                                <ListItemText>
                                    {
                                        maProject.name
                                    }
                                    <Button disabled={this.state.selected === null} fullWidth color={"primary"} variant={"contained"}
                                            onClick={_=>this.props.history.push("/project/"+this.props.match.params.id + "/city/" + this.state.city_id + "/" + maProject.id)}
                                    >
                                        select
                                    </Button>
                                    {/*<Button disabled={this.state.selected === null} fullWidth color={"primary"} variant={"contained"}*/}
                                    {/*        onClick={this.handleDelete(maProject.id)}*/}
                                    {/*>*/}
                                    {/*    delete*/}
                                    {/*</Button>*/}
                                </ListItemText>
                            </ListItem>
                        })}
                        <ListItem button dense onClick={_=>
                        {
                            let filtered_tdf = this.props.linkedTDFProjects.filter(t => t.city_id === this.state.city_id)
                            if (filtered_tdf.length > 0) {
                                this.setState({tdfProject: filtered_tdf[0].tdf_project_id})
                            } else
                            {
                                this.setState({tdfProject: null})
                            }
                            this.setState({addProject: true})

                        }}>
                            <AddIcon/>
                            <ListItemText>add project</ListItemText>
                        </ListItem>
                    </List>
                </Paper>

            </div>
        </Container>

    }
}

CityPreview.propTypes = {
    highlight: PropTypes.object,
    addProjectCallback: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
    // setColJustification: (id, col, txt)=>{
    //     dispatch(setColumnJustification(id, col, txt));
    // }
});


export default withRouter(CityPreview);