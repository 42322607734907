/**
 * Created by User on 19/10/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import Map from "../views/Map";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CityPreview from "../views/CityPreview";
import Paper from "@material-ui/core/Paper";

class ProjectCityListScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    render() {

        return <div style={{display:"flex", flexDirection:"row", width:"100%", height:"100%", padding:"10px", boxSizing:"border-box"}}>
            <div style={{flex:2, height:"100%"}}>
                <Map highlight={this.state.selected} markers={this.props.cities.map(m => ({
                    longitude: m.longitude,
                    latitude: m.latitude,
                    name: m.city_name,
                    id: m.id
                }))}/>
            </div>
            <div style={{display:"flex", flexDirection:"column", flex:1, height:"100%", position:"relative"}}>
                <div style={{position:"absolute", top:0, left:0, right:0, bottom:0, overflowY:"auto"}}>
                    <Typography style={{position:"sticky",paddingLeft:"10px", top:0, backgroundColor:"rgb(238, 238, 238)", zIndex:1201}} variant={"h6"}>Cities</Typography>

                    <div style={{padding:"10px"}}>
                        <List dense>
                        {
                            this.props.cities.map(m => {
                                return <ListItem selected={this.state.selected===m.id} button dense onClick={_=>this.setState({selected: m.id})}>
                                    <ListItemText>{m.city_name}</ListItemText>
                                </ListItem>
                            })
                        }
                        </List>
                    </div>
                    <div style={{position:"sticky", bottom:0, backgroundColor:"rgb(238, 238, 238)", zIndex:1201}} >
                        <Button disabled={this.state.selected === null} fullWidth color={"primary"} variant={"contained"}
                                onClick={_=>this.props.history.push("/project/"+this.props.match.params.id + "/city/" + this.state.selected)}
                        >
                            select
                        </Button>
                    </div>
                </div>
                <Divider/>
                <div>
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"row", flex:1, height:"100%"}}>
            <CityPreview addProjectCallback = {this.props.addProjectCallback}
                         highlight={this.state.selected}
                         maProjects = {this.props.maProjects}
                         tdfProjects = {this.props.tdfProjects}
                         linkedTDFProjects = {this.props.linkedTDFProjects}
                         deleteProjectCallback = {this.props.deleteProjectCallback}
                         cities={this.props.cities.map(m => ({
                                                        longitude: m.longitude,
                                                        latitude: m.latitude,
                                                        name: m.city_name,
                                                        id: m.id
            }))}/>
            </div>
        </div>
    }
}


ProjectCityListScreen.propTypes = {}


export default withRouter(ProjectCityListScreen);
