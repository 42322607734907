import React from 'react'
import PropTypes from "prop-types"
import {
    Legend,
    PolarAngleAxis,
    PolarGrid, PolarRadiusAxis, Radar,
    RadarChart,
    RadialBar,
    RadialBarChart,
    ResponsiveContainer,
    Tooltip
} from "recharts";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const radar_template = [
    {
        "subject": "Promote adaptive infrastructure",
        "code": "PAI",
        "fullMark": 5
    },
    {
        "subject": "Ensure good water sensitive guidance",
        "code": "GG",
        "fullMark": 5
    },
    {
        "subject": "Increase community capital",
        "code": "CC",
        "fullMark": 5
    },
    {
        "subject": "Achieve equity of essential services",
        "code": "EQU",
        "fullMark": 5
    },
    {
        "subject": "Improve productivity and resource efficiency",
        "code": "PRO",
        "fullMark": 5
    },
    {
        "subject": "Improve ecological health",
        "code": "EH",
        "fullMark": 5
    },
    {
        "subject": "Ensure quality urban space",
        "code": "QUS",
        "fullMark": 5
    }
]

const radial_template = [
    {
        "name": "Water Supply City",
        "uv": 1,
        "pv": 2400,
        "fill": "#ff2500"
    },
    {
        "name": "Sewered City",
        "uv": 0.7,
        "pv": 4567,
        "fill": "#FFA500"
    },
    {
        "name": "Drained City",
        "uv": 0.6,
        "pv": 1398,
        "fill": "#daff00"
    },
    {
        "name": "Waterway City",
        "uv": 0.45,
        "pv": 9800,
        "fill": "#66ff7c"
    },
    {
        "name": "Water Cycle City",
        "uv": 0.73,
        "pv": 3908,
        "fill": "#00ff25"
    },
    {
        "name": "Water Sensitive City",
        "uv": 0.81,
        "pv": 4800,
        "fill": "#00e521"
    },
]

class IndexPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentScreen: 0
        }
    }

    render() {

        let data = radial_template.map((d,i) => {
            return {
                ...d,
                rating: this.props.data[i+1]
            }
        })

        let radar_data = radar_template.map(v => {
            return {
                ...v,
                value: this.props.radar_data.index_values[v.code]
            }
        })
        if(this.props.target !== undefined){

            radar_data = radar_data.map(v => {
                return {
                    ...v,
                    target_value: this.props.target.details.index_values[v.code]
                }
            })
        }

        return  <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
                <Tabs  onChange={(_, e) => this.setState({currentScreen:e})} value={this.state.currentScreen}>
                    <Tab label={"Indicators"}/>
                    <Tab label={"City State"}/>
                </Tabs>
            <div style={{flex:1}}>

                {this.state.currentScreen === 0 && <div style={{width:"100%", height:"100%"}}>

                    <ResponsiveContainer width={"100%"} height={"100%"}>
                        <RadarChart outerRadius={"70%"} data={radar_data}>
                            <PolarGrid />
                            <PolarAngleAxis
                                tick={_=><foreignObject style={{
                                    fontSize:"0.6em",
                                }}
                                        x={_.textAnchor === "start" ? _.x : _.textAnchor === "end" ? _.x - 100: _.x - 50}
                                        y={_.payload.coordinate > 0 || _.payload.coordinate < -180 ? _.y - 100: _.y}
                                        height={100}
                                        width={100}>
                                    <div style={{width:"100%", height:"100%",
                                        display:"flex",
                                        flexDirection:"column",}}>
                                        {_.payload.coordinate > 0 || _.payload.coordinate < -180 ? <div style={{flex:1}}/> : ""}
                                        <div style={{textAlign: _.textAnchor === "start" ? "left": _.textAnchor === "end"?"right": "center"}}>{_.payload.value}</div>
                                    </div>
                                </foreignObject>}
                                dataKey="subject"  />
                            <PolarRadiusAxis tickCount={6} angle={61} domain={[0, 5]}
                                             // @Todo fix font size in graph


                            />
                            <Radar dataKey="value" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                            <Radar dataKey="target_value" fill={"none"}
                                   stroke="rgb(255,89,0)"
                                   strokeWidth={2}
                                   strokeDasharray={4} fillOpacity={0.6} />
                        </RadarChart>
                    </ResponsiveContainer>
                </div>
                }
                {this.state.currentScreen === 1 && <div style={{width:"100%", height:"100%"}}>
                <ResponsiveContainer width={"100%"} height={"100%"}>
                    <RadialBarChart
                        cx={"30%"}
                        cy={"50%"}
                        innerRadius="20%"
                        outerRadius="90%"
                        data={data}
                        startAngle={90}
                        endAngle={-270}
                    >
                        <RadialBar  minAngle={15} cornerRadius={10}  background clockWise={false} dataKey='rating' />

                        <Legend formatter={(l, p) => l + " (" + p.payload.rating.toFixed() + "%)"} iconSize={10} width={230} height={140} layout='vertical' verticalAlign='middle' align="right" />
                        <Tooltip formatter={_=>_.toFixed()} labelFormatter={_=>radial_template[_].name}/>
                    </RadialBarChart>
                </ResponsiveContainer>
            </div>}
            </div>
        </div>

    }
}


IndexPreview.propTypes = {}

export default IndexPreview;
