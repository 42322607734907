/**
 * Created by User on 19/10/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ReactPlaceholder from "react-placeholder";
import AddIcon from "@material-ui/icons/Add"
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class ProjectOverviewScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addingTDF: false,
            selectedTDFProject: null,
            selectedCity: null
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    handleAddTDF = (tdf_id, city_id) => {
        this.props.addTDFCallback(tdf_id, city_id)
        this.setState({addingTDF: false})
    }

    render() {

        const tdf_keys = ["name", "whos_involved", "time_period", "lead_fac_analyst", "city"]
        const tdf_names = ["Name", "Whos Involved", "Time Period", "Lead Fac Analyst", "City"]
        const project = this.props.project;
        const city = this.props.cities.find(c => c.id === parseInt(this.props.match.params.city_id));
        return <div style={{flex: 0,display:"flex", flexDirection:"column", boxSizing:"border-box"}}>
            <Dialog maxWidth={"lg"} fullWidth open={this.state.addingTDF}>
                <DialogTitle>Connect a TDF Project</DialogTitle>
                <DialogContent dividers style={{height:"50vh", padding:0, margin:0, display:"flex", flexDirection:"row"}}>
                    <div style={{flex:1}}>
                        {
                            this.props.tdf_projects.map(tdf => {
                                return <ListItem selected={this.state.selectedTDFProject?.id === tdf.id} dense button onClick={_=>this.setState({selectedTDFProject: tdf})}>
                                    <ListItemText>
                                        {tdf.name}
                                    </ListItemText>
                                </ListItem>
                            })
                        }
                    </div>
                    <Divider orientation={"vertical"}/>
                    <div style={{flex:3, display:"flex", flexDirection:"row"}}>
                        {
                            this.state.selectedTDFProject && <div style={{flex:"1 0 auto"}}>
                                {tdf_keys.map((k,i) => {
                                    return <div>
                                        <div  style={{margin:"0px 30px"}}>{tdf_names[i]}</div>
                                        <Divider orientation={"horizontal"}/>
                                    </div>
                                })}
                            </div>
                        }
                        {
                            this.state.selectedTDFProject && <div style={{flex:"1 0 auto"}}>
                                {tdf_keys.map((k,i) => {
                                    return <div>
                                        <div  style={{margin:"0px 30px", textAlign:"right"}}>{this.state.selectedTDFProject[k]}</div>
                                        <Divider orientation={"horizontal"}/>
                                    </div>
                                })}
                            </div>
                        }
                        {
                            this.state.selectedTDFProject && <div style={{flex:"3 1"}}>
                                {tdf_keys.map((k,i) => {
                                    return <div>
                                        <div  style={{margin:"0px 30px"}}>&nbsp;</div>
                                        <Divider orientation={"horizontal"}/>
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                    <Typography style={{position:"sticky",paddingLeft:"10px", top:0,  zIndex:1201}} variant={"h6"}>Cities</Typography>

                    <div style={{padding:"10px", overflowY:"auto"}}>
                        <List dense>
                            {
                                this.props.cities.map(m => {
                                    return <ListItem selected={this.state.selectedCity===m.id} button dense onClick={_=>this.setState({selectedCity: m.id})}>
                                        <ListItemText>{m.city_name}</ListItemText>
                                    </ListItem>
                                })
                            }
                        </List>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button size={"small"} variant={"outlined"} color={"secondary"} onClick={_=>this.setState({addingTDF: false})}>
                        cancel
                    </Button>

                    <Button  size={"small"} variant={"outlined"} color={"primary"} onClick={_=>this.handleAddTDF(this.state.selectedTDFProject.id,this.state.selectedCity)}>
                        confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{display:"flex", flexDirection:"column", height:"100%", margin:"10px", flex:1}}>
                <div style={{flex:"1 1 0", display:"flex", flexDirection:"row"}}>
                    <div style={{flex:1, minHeight:"40vh"}}>
                        <Paper style={{height:"100%", padding:"10px", boxSizing:"border-box"}}>
                            <Typography variant={"h6"}>{project.name}</Typography>
                            <br/>
                            <div>
                                <b>Created by: </b>{project.creator_email}
                            </div>
                            <div>
                                <b>Cities: </b> {this.props.cities.length}
                            </div>
                        </Paper>

                    </div>
                    <div style={{width:"10px"}}/>
                    <div style={{flex:1}}>
                        <Paper style={{height:"100%", padding:"10px", boxSizing:"border-box"}}>
                            <Typography variant={"h6"}>
                                Connected TDF Projects
                            </Typography>
                            <List dense>
                                {this.props.linked_tdf_projects.map(linked => {
                                    return <ListItem dense button>
                                        <ListItemText>
                                            {
                                                "City: " + this.props.cities.find(city => city.id === linked.city_id)?.city_name + "->" +
                                                "TDF: " +
                                                this.props.tdf_projects.find(proj => proj.id === linked.tdf_project_id)?.name

                                            }
                                        </ListItemText>
                                    </ListItem>
                                })}
                                <ListItem button dense onClick={_=>this.setState({addingTDF: true})}>
                                    <AddIcon/>
                                    <ListItemText>add new</ListItemText>
                                </ListItem>
                            </List>
                        </Paper>
                    </div>
                </div>
                <div style={{height:"10px"}}/>
                <div style={{flex:"1 1 0", display:"flex", flexDirection:"row"}}>

                    <div style={{flex:1, minHeight:"10vh"}}>
                        <Paper style={{height:"100%", display:"flex", alignItems:"center", justifyContent:"center", padding:"10px", boxSizing:"border-box"}}>
                            <Button
                                onClick={_=>this.props.history.push("/project/" + this.props.match.params.id +"/city")}
                                variant={"outlined"} color={"primary"}>Jump to cities</Button>
                        </Paper>
                    </div>
                    <div style={{width:"10px"}}/>
                    <div style={{flex:1}}/>
                </div>
            </div>
        </div>;
    }
}


ProjectOverviewScreen.propTypes = {
    project: PropTypes.object,
    city: PropTypes.object,
}


export default withRouter(ProjectOverviewScreen);
