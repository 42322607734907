/**
 * Created by User on 17/11/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter, Route} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ProjectListScreen from "./screens/ProjectListScreen";
import ProjectScreen from "./screens/ProjectScreen";
import {projects} from "./tmp_data";
import LoginScreen from "./screens/LoginScreen";
import API, {combined} from "./API";
import Button from "@material-ui/core/Button";

class Root extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authed: false,
            checkingAuth: true,
        };
    }

    componentDidMount() {
        let token = localStorage.getItem("token");
        let email = localStorage.getItem("email");
        if(token && email){
            API.checkToken.post(email, token).then(f => {
                this.setState({checkingAuth: false})
                if(f.valid !== true){
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    this.setState({
                        authed: false
                    })
                }else{
                    this.setState({
                        authed: true
                    })
                }
            })
        }else{
            this.setState({
                checkingAuth:false,
            })
        }

    };

    componentWillUnmount() {
    };

    handleLogin = (data) => {
        localStorage.setItem("token", data["token"])
        localStorage.setItem("email", data["username"])
        this.setState({
            authed: true
        })


    }

    handleLogout = () => {

        localStorage.removeItem("token");
        localStorage.removeItem("email");

        this.setState({authed: false})
    }

    render() {
        return  <BrowserRouter >
            { this.state.authed ?
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#EEE"
                }}>
                    <AppBar  position={"sticky"} style={{backgroundColor: "#044E54", zIndex: 1201}}>
                        <Toolbar variant={"dense"}>
                            <Typography variant="h6">
                                Management Actions
                            </Typography>
                            <div style={{flex:1}}/>
                            <Button
                                onClick={this.handleLogout}
                                style={{float:"right", color:"white", borderColor:"rgba(255,255,255, 0.7)"}}
                                size={"small"}
                                variant={"outlined"}>
                                log out
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Route exact path={"/"}>
                        <ProjectListScreen/>
                    </Route>
                    <Route path={"/project/:id"} render={props => {
                        return <ProjectScreen project_id={parseInt(props.match.params.id)}/>
                    }}>
                    </Route>
                </div>
            : this.state.checkingAuth ?
                <div/> :
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#EEE"
                }}>
                    <LoginScreen onLogin={this.handleLogin}/>
                </div>
            }
        </BrowserRouter>;
    }
}


Root.propTypes = {}


export default Root;
