import * as React from "react";


export default (WrappedComponent, fetcher, passProps=false) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
                refreshing: false,
                data: null,
            }
        }

        refreshData = () => {

            let primedFetcher = fetcher
            if(passProps){
                primedFetcher = fetcher(this.props)
            }
            this.setState({
                refreshing: true
            })
            return primedFetcher().then(d=>this.setState({
                refreshing:false,
                data:d,
            }))
        }

        componentDidMount() {
            let primedFetcher = fetcher
            if(passProps){
                primedFetcher = fetcher(this.props)
            }

            primedFetcher().then(d=>this.setState({
                loading:false,
                data:d,
            }))
        }

        componentWillUnmount() {

        }

        render(){
            return <WrappedComponent refresh_data={this.refreshData} loading={this.state.loading} data={this.state.data} {...this.props}/>
        }
    }
}