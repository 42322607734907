import React from 'react';
import {PropTypes} from "prop-types";
import Paper from "@material-ui/core/Paper/Paper";
import {styles} from "./MatrixCell"
import Tooltip from "@material-ui/core/Tooltip/Tooltip";


const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}


const buildMatrix =()=>({
    entries: [...Array(6).keys()].map(
        _=>[...Array(5).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(5).keys()].map(_=>""),
})

class MatrixCellPriority extends React.Component {

    constructor(props) {
        super(props);
    }


    static getBackgroundColor(priority) {
        return ["lightgreen", "yellow", "red"][priority];
    }


    render() {
        const highlight = this.props.highlight;
        let priority = this.props.matrixForceData.entries[this.props.row][this.props.col].priority
        return (<Paper style={{...styles.innerWrappers, border:"solid " + highlight.size + " " + highlight.color, backgroundColor: MatrixCellPriority.getBackgroundColor(priority)}}>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                            {this.props.cell.text}
                    </div>
        </Paper>)
    }
}

MatrixCellPriority.propTypes = {
    row: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    priority: PropTypes.number,
    matrixId: PropTypes.number.isRequired,
    cell: PropTypes.object.isRequired,
};


const mapStateToProps = (state, ownProps) => {
    let matrix;
    if(ownProps.matrixId !== undefined && ownProps.matrixId !== null){
        matrix = state.practiceChanges.filter(v => v.id === ownProps.matrixId)[0].matrixData;
    }else{
        matrix = buildMatrix()
    }
    let entry = matrix.entries[ownProps.row][ownProps.col];
    return {
        priority: entry.priority,
    }
};

const mapDispatchToProps = dispatch => ({})

export default MatrixCellPriority;