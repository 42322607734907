
import {fetch} from "whatwg-fetch";

import {tdf_url as url} from "./config";


export const TDF_GET = (url) => {
    return fetch(url, {
        method: "GET",
        mode:"cors",
        headers: {
            useremail: localStorage.getItem("email"),
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}


export const TDF_GET_FILE = (url, download_filename="download.zip") => {
    return fetch(url, {
        method: "GET",
        mode:"cors",
        headers: {
            useremail: localStorage.getItem("email"),
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(response => {
        console.log(response);
        return response.blob()
    }).then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = download_filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
        }

    );
}
export const TDF_POST_FILE = (url, file) => {
    let form_data = new FormData();
    form_data.append("file", file);
    form_data.append("file_name", file.name);

    return fetch(url, {
        method: "POST",
        body: form_data,
        mode:"cors",
        headers: {
            useremail: localStorage.getItem("email"),
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());;
}

export const TDF_POST = (url, data) => {
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        mode:"cors",
        headers: {
            useremail: localStorage.getItem("email"),
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}


export const TDF_PUT = (url, data, handlers) => {
    return fetch(url, {
        method: "PUT",
        mode:"cors",
        body: JSON.stringify(data),
        headers: {
            useremail: localStorage.getItem("email"),
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}

export const TDF_DELETE = (url) => {
    return fetch(url, {
        method: "DELETE",
        mode:"cors",
        headers: {
            useremail: localStorage.getItem("email"),
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}


export const getTDFProjects = () => {

    return TDF_GET(url + "/projects");
}

const dance_endpoint_injection = (store, func) => {

    return func.bind({
        getState: store.getState,
        dispatch: store.dispatch
    });
}


const noop = _=>{
    throw "Unimplemented api endpoint"
};

class Endpoint {

    constructor(store, handlers) {
        this.get = dance_endpoint_injection(store, handlers.get || noop);
        this.put = dance_endpoint_injection(store, handlers.put || noop);
        this.post = dance_endpoint_injection(store, handlers.post || noop);
        this.delete = dance_endpoint_injection(store, handlers.delete || noop);
    }

}

export const combined = (dict) => {
    let promises = Object.keys(dict).map(f => {
        return dict[f]().then(r => {
            return {k:f, data:r}
        })
    })
    return _=>Promise.all(promises).then(f => {
        let result = {};

        f.map(v => {
            result[v.k] = v.data
        })

        return result;
    })

}

let TDF_API = {};

TDF_API.project = new Endpoint({},{
    get:getTDFProjects,
});






export default TDF_API;
