/**
 * Created by User on 20/11/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import {Add, Remove} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";

class IndicatorAccordian extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !(this.props.detailList === nextProps.detailList
            && this.props.summary === nextProps.summary
            && this.props.comparison_data === nextProps.comparison_data
            && this.props.editable === nextProps.editable
            && this.props.relative === nextProps.relative
            && this.props.highlightList === nextProps.highlightList
            && this.props.ratingBaseValues === nextProps.ratingBaseValues
            && this.props.ratingValues === nextProps.ratingValues);
    }

    setRatingValue = (index, diff, base_val) => {

        this.props.setRatingValueCallback(index, diff, base_val)
    }

    renderNonEditable = (j) => {
        let selectable_content = "";
        if(this.props.selectable){
            selectable_content = <Checkbox
                size={"small"}
                value={this.props.selectedIndicators[j]}
                onChange={_=>this.props.onIndicatorSelect(j)}
            />
        }
        if(this.props.relative){

            return <div style={{
                color: (this.props.ratingBaseValues[j].rating - this.props.comparison_data[j].rating) < 0 ?
                    "red" :
                    (this.props.ratingBaseValues[j].rating - this.props.comparison_data[j].rating) === 0 ?
                        "black" :
                        "green",
                display: "flex",
                flex:1,
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "2px",
                marginRight: "2px"
            }}>
                <div style={{flex:1, textAlign:"center"}}>
                    {(this.props.ratingBaseValues[j].rating - this.props.comparison_data[j].rating) <= 0 ? "" :
                        "+"}{(this.props.ratingBaseValues[j].rating - this.props.comparison_data[j].rating).toFixed(1)}
                </div>
                <div>
                    {selectable_content}
                </div>

            </div>
        }else if(this.props.impact){
            let diff = this.props.ratingValues[j]
            return <div style={{
                flex:1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "2px",
                marginRight: "2px"
            }}>
                <div style={{flex:1, textAlign:"center", color: diff < 0 ? "red":diff > 0? "green": "black"}}>
                    {(this.props.ratingBaseValues[j].rating + this.props.ratingValues[j]).toFixed(1)}
                    <span style={{visibility:
                        diff === 0 ? "hidden": "visible",
                        marginLeft:"2px"
                    }}>({(this.props.ratingBaseValues[j].rating).toFixed(1)})</span>

                </div>
            </div>
        }else{
            return <div style={{
                flex:1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "2px",
                marginRight: "2px"
            }}>
                <div style={{flex:1, textAlign:"center"}}>
                {(this.props.ratingBaseValues[j].rating).toFixed(1)}
                </div>
                <div>
                    {selectable_content}
                </div>
            </div>
        }
    }

    calculateAverage = () => {

        if(this.props.relative) {

            let ave = this.props.detailList.map((f, j) => this.props.ratingBaseValues[j].rating - this.props.comparison_data[j].rating)
                .reduce((p,c) => p+c,0)/this.props.ratingBaseValues.length;

            return <span style={{color: ave < 0 ? "red" : ave > 0 ? "green" : "black"}}>{ave > 0 && "+"}{ave.toFixed(1)}</span>

        }
        if(this.props.impact){
            let ave = this.props.detailList.map((f, j) => this.props.ratingBaseValues[j].rating + this.props.ratingValues[j])
                .reduce((p,c) => p+c,0)/this.props.ratingBaseValues.length;

            let raw_ave = this.props.detailList.map((f, j) => this.props.ratingBaseValues[j].rating)
                .reduce((p,c) => p+c,0)/this.props.ratingBaseValues.length;

            return <span>
                <span style={{color: raw_ave < ave ? "green": raw_ave > ave ? "red": "black"}}>{ave.toFixed(1)}</span>
                <span style={{marginLeft:"2px", visibility: ave === raw_ave ? "hidden":"default"}}>({raw_ave.toFixed(1)})</span>
            </span>
        }
        return <span>{(this.props.detailList.map((f, j) => this.props.ratingBaseValues[j].rating)
            .reduce((p,c) => p+c,0)/this.props.ratingBaseValues.length).toFixed(1)}</span>;
    }

    render() {
        let ave = this.calculateAverage()
        let hl = false;
        let all_hl = false;
        if(this.props.highlightList !== undefined && this.props.highlightList !== null){
            hl = this.props.detailList.some((f, j) => this.props.highlightList.includes(this.props.ratingBaseValues[j].index_detail_id));
            all_hl = this.props.detailList.every((f, j) => this.props.highlightList.includes(this.props.ratingBaseValues[j].index_detail_id));

        }
        return <div style={{flex:1, fontSize:"0.8em", margin:"10px", maxWidth:"calc(50% - 20px)"}}>
            <Accordion>
                <AccordionSummary style={{display:"flex", alignItems:"center", backgroundColor:`rgb(${hl ? 240: 255 },${hl ? 240: 255 }, 255)` }}>
                    {this.props.summary} <div style={{flex:1}}/>{ave}

                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width:"100%"}}>
                        {this.props.detailList.filter((d,i) => {
                            if(this.props.renderFilter){
                                return this.props.renderFilter[i]
                            }
                            return true;
                        }).map((f, j) => {
                            let hl = false;
                            if(this.props.highlightList !== undefined && this.props.highlightList !== null){
                                hl = this.props.highlightList.includes(this.props.ratingBaseValues[j].index_detail_id)
                            }

                            return <div style={{display:"flex", margin:"6px 4px", backgroundColor:`rgb(${hl ? 240: 255 },${hl ? 240: 255 }, 255)`}}>
                                <div style={{flex:3}}>
                                    <span style={{fontWeight:500}}>{f.split(" ")[0]}</span> {f.split(" ").slice(1).join(" ")}
                                </div>
                                {this.props.editable === false ?
                                    this.renderNonEditable(j)
                                        :[
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <Button style={{minWidth: "0px", padding: "0px"}}
                                                onClick={_ => this.setRatingValue(j, -0.1, this.props.ratingBaseValues[j].rating)}
                                                color={"primary"} variant={"contained"} size={"small"}>
                                            <Remove fontSize={"inherit"}/>
                                        </Button>
                                    </div>,
                                    <div style={{
                                        color: this.props.ratingValues[j] < 0 ? "red" : (this.props.ratingValues[j] > 0 ? "green" : "black"),
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "2px",
                                        marginRight: "2px"
                                    }}>
                                        {Math.abs(this.props.ratingBaseValues[j].rating + this.props.ratingValues[j]).toFixed(1)}
                                    </div>,
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <Button style={{minWidth: "0px", padding: "0px"}}

                                        onClick={_ => this.setRatingValue(j, 0.1, this.props.ratingBaseValues[j].rating)}
                                        color={"primary"} variant={"contained"} size={"small"}>
                                        <Add fontSize={"inherit"}/>
                                    </Button>
                                    </div>]
                                }
                            </div>
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>;
    }
}


IndicatorAccordian.propTypes = {
    relative: PropTypes.bool,
    editable: PropTypes.bool,
    ratingValues: PropTypes.array,
    summary: PropTypes.string,
    detailList: PropTypes.array,
}


export default IndicatorAccordian;
