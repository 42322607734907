/**
 * Created by User on 8/12/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart, RadialBar,
    RadialBarChart,
    ResponsiveContainer,
    Tooltip
} from "recharts";
import {calculate_continuum} from "../Continuum";

const radialPresentationData = [
    {
        "name": "Water Supply City",
        "fill": "#ff2500"
    },
    {
        "name": "Sewered City",
        "fill": "#FFA500"
    },
    {
        "name": "Drained City",
        "fill": "#daff00"
    },
    {
        "name": "Waterway City",
        "fill": "#66ff7c"
    },
    {
        "name": "Water Cycle City",
        "fill": "#00ff25"
    },
    {
        "name": "Water Sensitive City",
        "fill": "#00e521"
    },
]

class RadialChart extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    render() {

        let radial, radar, maxed_indicators;
        let base_radar, target_radar;
        let radial_conflict = [];
        if (this.props.managementActions.length > 0) {
            radial = calculate_continuum(this.props.baseRatings, this.props.managementActions).slice(1);
        }else{
            radial=calculate_continuum(this.props.baseRatings, []).slice(1);
        }
        return <ResponsiveContainer width={"100%"} height={"100%"}>
                    <RadialBarChart
                        cx={"30%"}
                        cy={"50%"}
                        innerRadius="20%"
                        outerRadius="75%"
                        data={radial.map((v,i) => ({...radialPresentationData[i], "rating": v}))}
                        startAngle={90}
                        endAngle={-270}
                    >
                        <RadialBar minAngle={15} cornerRadius={10}  background clockWise={false} dataKey='rating' />
                        <Legend formatter={(l, p) => l + " (" + p.payload.rating.toFixed() + "%)"} iconSize={10} width={230} height={140} layout='vertical' verticalAlign='middle' align="right" />
                        <Tooltip formatter={_=>_.toFixed()} labelFormatter={_=>radialPresentationData[_].name}/>
                    </RadialBarChart>
                </ResponsiveContainer>;
    }
}


RadialChart.propTypes = {}


export default RadialChart;
