/**
 * Created by User on 19/10/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, withRouter} from "react-router-dom";
import {Switch} from "react-router";
import CityScreen from "./CityScreen";
import Typography from "@material-ui/core/Typography";
import ProjectCityListScreen from "./ProjectCityListScreen";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ProjectOverviewScreen from "./ProjectOverviewScreen";
import DataLoader from "../DataLoader";
import API, {combined} from "../API";
import TDF_API from "../TDF_API";
import CircularProgress from "@material-ui/core/CircularProgress";

class ProjectScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    };



    componentWillUnmount() {
    };

    handleAddTDF = (tdf_id, city_id) => {
         API.projectTDF.post(this.props.project_id, tdf_id, city_id).then(_ => {
            this.props.refresh_data();
        })
    }

    handleAddProject = (city_id, project_name, tdf_project_id) => {
        API.MAProject.post(this.props.project_id, city_id, project_name, tdf_project_id).then(_ => {
            this.props.refresh_data();
        })
    }

    handleDeleteProject = (project_id) => {
        API.MAProject.delete(project_id).then(f => {
            this.props.refresh_data();
        });
    }


    getCityId = (url) => {
        return parseInt(url.split("/")[4]);
    }
    getProjectId = (url) => {
        return parseInt(url.split("/")[5]);
    }

    getProject = (url) => {
        const project_id = this.getProjectId(url);
        return this.props.data.ma_projects.find(p => p.id === project_id);
    }

    render() {
        if(this.props.loading) {
            return <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", width:"100%", height:"100%"}}>
                <CircularProgress variant={"indeterminate"} size={100}/>
                loading
            </div>
        }
        return <div style={{width:"100%", height:"100%", display:"flex"}}>
                <div style={{width:"100px", height:"100%", flexShrink:0}}>
                    <Paper style={{width:"100%", height:"100%"}} square elevation={3}>
                        <List>
                            {/*<ListItem button onClick={_=>this.props.history.push("/project/" + this.props.match.params.id)}>*/}
                            {/*    /!*<ListItemText>*!/*/}
                            {/*    /!*    Overview*!/*/}
                            {/*    /!*</ListItemText>*!/*/}
                            {/*</ListItem>*/}
                            <ListItem button onClick={_=>this.props.history.push("/project/" + this.props.match.params.id +"/city")}>
                                <ListItemText>
                                    Cities
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Paper>
                </div>
            <Container style={{flex:"1"}} maxWidth={"xl"}>
                <Route exact path={this.props.match.path} >
                    <div style={{display:"flex", flexDirection:"column", maxWidth:"100%", maxHeight:"100%"}}>
                        <ProjectOverviewScreen
                            addTDFCallback={this.handleAddTDF}
                            linked_tdf_projects={this.props.data.linked_tdf_projects.filter(
                                v => this.props.data.tdf_projects.some(tdf => tdf.id === v.tdf_project_id )
                            )}

                            tdf_projects={this.props.data.tdf_projects}
                            cities={this.props.data.cities}
                            project={this.props.data.project}/>
                    </div>
                </Route>
                <Route exact path={this.props.match.path + "/city"}>

                    <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%"}}>
                        <Typography variant={"h6"}>{this.props.data.project.name}</Typography>
                        <div style={{flex:"1"}}>
                            <ProjectCityListScreen project={this.props.data.project}
                                                   addProjectCallback={this.handleAddProject}
                                                   deleteProjectCallback={this.handleDeleteProject}
                                                   tdfProjects={this.props.data.tdf_projects}
                                                   linkedTDFProjects={this.props.data.linked_tdf_projects.filter(
                                                        v => this.props.data.tdf_projects.some(tdf => tdf.id === v.tdf_project_id )
                                                    )}
                                                   maProjects={this.props.data.ma_projects.filter(
                                                       (v) => {
                                                           let ltdf = this.props.data.linked_tdf_projects.find(tdf => v.city_id === tdf.city_id)
                                                           if (!ltdf) return false;
                                                           return this.props.data.tdf_projects.some(tdf => tdf.id === ltdf.tdf_project_id )
                                                       })

                                                    }
                                                   cities={this.props.data.cities}/>
                        </div>
                    </div>
                </Route>
                <Route path={this.props.match.path + "/city/:city_id/:ma_project_id"}>
                    <CityScreen linked_tdf_projects={
                        this.props.data.tdf_projects.filter(a => a.id === this.getProject(this.props.location.pathname)?.tdf_project_id )}
                                project={this.props.data.project}
                                cities={this.props.data.cities}/>
                </Route>
            </Container>
        </div>
    }
}


ProjectScreen.propTypes = {
    project: PropTypes.object,
}


export default withRouter(DataLoader(ProjectScreen, props=>{
    return combined({
        project:_=>API.project.get(props.match.params.id),
        cities:_=>API.projectCities.get(props.match.params.id),
        linked_tdf_projects:_=>API.projectTDF.get(props.match.params.id),
        tdf_projects:_=>TDF_API.project.get(),
        ma_projects:_=>API.MAProject.get(props.match.params.id)
    });
}, true));
