/**
 * Created by User on 19/10/2020
 */

import React, {Component} from 'react';
import mapboxgl from "mapbox-gl"
import PropTypes from 'prop-types';

class Map extends Component {

    constructor(props) {
        super(props);
        this.domRef = React.createRef();
        this.state = {
            marker_map:{},
            map: null,
            width: 0,
            lng: 5,
            lat: 34,
            zoom: 2
        };
    }

    componentDidMount() {
        mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXN0aWFudXJpY2giLCJhIjoiNFk1SE43MCJ9.jsgfZCgEcEV9QvZ7R8xsOg';
        const bnds = new mapboxgl.LngLatBounds();
        this.props.markers.map(m => {
            console.log(m.longitude, m.latitude)
            bnds.extend([m.longitude, m.latitude])
        })
        const map = new mapboxgl.Map({
            container: this.domRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            zoom: this.state.zoom
        });
        const marker_map = {};
        this.props.markers.map(m => {
            marker_map[m.id] = new mapboxgl.Marker()
                .setLngLat([m.longitude, m.latitude])
                .addTo(map)
                .setPopup(new mapboxgl.Popup({closeButton: false}).setHTML(m.name).addTo(map))
                .togglePopup()
        })
        map.fitBounds(bnds, {padding:60})
        window.addEventListener("resize", this.resize)
        this.setState({width:this.domRef.current.clientWidth, height:this.domRef.current.clientHeight, map: map, marker_map})
    };

    resize = () => {
        this.state.map.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.map === null && this.state.map !== null){
            const bnds = new mapboxgl.LngLatBounds();
            this.props.markers.map(m => {
                bnds.extend([m.longitude, m.latitude])
            })
            this.state.map.resize();
            this.state.map.fitBounds(bnds, {padding:60, })
        }
        if(this.props.highlight !== prevProps.highlight){
            Object.keys(this.state.marker_map).map(i => {
                if(parseInt(i) === parseInt(this.props.highlight)){
                    this.state.marker_map[i].togglePopup();
                    this.state.map.flyTo({center:this.state.marker_map[i].getLngLat()});
                }else if(prevProps.highlight !== null && parseInt(i) === parseInt(prevProps.highlight)) {

                    this.state.marker_map[i].togglePopup();
                }
            })

        }
    }

    render() {
        return <div style={{height:"100%"}} ref={this.domRef}/>;
    }
}


Map.propTypes = {
    highlight: PropTypes.object,
}


export default Map;
