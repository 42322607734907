/**
 * Created by User on 19/10/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import ProjectCard from "../views/ProjectCard";
import Grid from "@material-ui/core/Grid";
import {projects} from "../tmp_data";
import Container from "@material-ui/core/Container";
import API from "../API";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataLoader from "../DataLoader";



class ProjectListScreen extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    render() {
        return <Container style={{flex:"1"}} maxWidth={"lg"}>
            <div style={{paddingTop:"10px"}}>
                <div style={{fontSize:"1.2em", paddingBottom:"10px"}}>
                    Index Projects
                </div>
                <Grid container spacing={2}>
                { this.props.loading ?
                    <Grid item xs={12} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                        <CircularProgress variant={"indeterminate"} size={100}/>
                        loading projects
                    </Grid>
                    :
                    this.props.data.length === 0 ?
                        <div style={{width:"100%", paddingTop:"100px", textAlign:"center"}}>
                            You do not have access to any index projects.
                        </div>
                    :this.props.data.map(p => {
                        return <Grid item  xs={12} sm={12} md={6} lg={4} xl={3}>
                            <ProjectCard project={p} onClick={_=>this.props.history.push("project/" + p.id)}/>
                        </Grid>
                    })
                }
                </Grid>

            </div>
        </Container>
    }
}


ProjectListScreen.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.object,
}

export default withRouter(DataLoader(ProjectListScreen, API.project.get));
