import React from 'react'
import PropTypes from "prop-types"
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import {Add} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Matrix from "../matrix/Matrix";
import {generate_matrix} from "../matrix/data_gen";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {detail_ids} from "./ManagementActionPanel";


const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}


const buildMatrix =()=>({
    entries: [...Array(6).keys()].map(
        _=>[...Array(5).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(5).keys()].map(_=>""),
})

class TDFPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewingMatrix: null,
            dialogOpen: false,
            selectedTDFProject: null,
            selectedIssue: null,
        }
    }

    handleAddIssue = () => {
        this.setState({
            dialogOpen: false,
        })

        this.props.addIssueCallback(this.state.selectedIssue.id);
    }

    render() {
        console.log("this.props.linked_tdf_projects", this.props.linked_tdf_projects)
        let issues = this.props.linked_tdf_projects.flatMap(p => p.practice_changes);
        return <div style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, overflowY:"auto"}}>
                <div
                    style={{position: "sticky", backgroundColor: "white", top: 0,zIndex:100}}>
                    TDF - Issues
                </div>
                <div style={{position:"relative"}}>
                    {
                        issues.map((f,i) => {

                            let indicators = this.props.selectedIndicators;
                            let any = false;
                            if(indicators !== null) {
                                let major_index = 0;
                                let acc_length = 0;
                                for (let i = 0; i < f.indicators.length; i++) {
                                    let next = f.indicators[i] - 1;
                                    while ((next - acc_length) >= indicators[major_index].length) {
                                        acc_length += indicators[major_index].length
                                        major_index += 1;
                                    }
                                    any = any || indicators[major_index][next - acc_length];
                                }
                            }
                            return <ListItem
                                        selected={f.id === this.props.highlightedIssues}
                                        style={{border: any ? "solid 2px skyblue" : "none"}}
                                        dense
                                        button
                                        onClick={_=>this.props.highlightIssueCallback(f.id)}>
                                <ListItemSecondaryAction>
                                    <Button
                                        onClick={_=>this.setState({viewingMatrix: f.practice_change_data})}
                                        variant={"outlined"}
                                        color={"primary"}
                                        size={"small"}>
                                        matrix
                                    </Button>
                                </ListItemSecondaryAction>
                                <ListItemText secondary={f.practice_change_name}>
                                    {f.issue_name}
                                </ListItemText>
                            </ListItem>
                        })
                    }
                    <Dialog maxWidth={"lg"} fullWidth open={this.state.viewingMatrix !== undefined && this.state.viewingMatrix !== null}>
                        <DialogTitle>
                            TDF Matrix
                        </DialogTitle>
                        <DialogContent dividers style={{height:"60vh"}}>
                            <Matrix clickable={false} plainRender matrixForceData={this.state.viewingMatrix} />
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"outlined"} color={"primary"} onClick={_=>this.setState({viewingMatrix: undefined})}>dismiss</Button>
                        </DialogActions>
                    </Dialog>
                </div>
        </div>
    }
}


TDFPreview.propTypes = {
    selectedIndicators: PropTypes.any,
}

export default TDFPreview;
