import {detail_ids} from "./views/ManagementActionPanel";


export const formatIndicators =  (indicators) => {
    let major_index = 0;
    let acc_length = 0;
    const indexes = [];
    for(let i = 0; i < indicators.length; i++){
        let next = indicators[i]-1;
        while((next - acc_length) >= detail_ids[major_index].length){
            acc_length += detail_ids[major_index].length
            major_index += 1;
        }
        indexes.push([major_index, next-acc_length]);
    }

    return indexes;
}