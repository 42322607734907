/**
 * Created by User on 17/11/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Lock, Person} from "@material-ui/icons";
import CardActions from "@material-ui/core/CardActions";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import API from "../API";
import CircularProgress from "@material-ui/core/CircularProgress";

class LoginScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logging_in: false,
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    handleTextChange = (key) => e => {
        let newstate = {};
        newstate[key] = e.target.value;
        this.setState(newstate);
    }

    handleLoginClick = () => {
        this.setState({
            logging_in: true,
        })

        API.login.post(this.state.username, this.state.password).then(f => {
            this.setState({
                logging_in: false,
            })
            this.props.onLogin(f)
        }).catch(f => {

            this.setState({
                logging_in: false,
            })
        })
    }

    render() {
        return <div style={{display:"flex", flexDirection:"row",width:"100%", height:"100%"}}>
                <div style={{flexBasis:"60%",margin:"auto"}}>
                    <Card raised={true} style={{padding:"2%", position:"relative", boxSizing:"border-box", margin:"0% 10%",}}>
                        {this.state.logging_in &&
                            <div style={{position:"absolute",
                                display:"flex",
                                flexDirection:"column",
                                zIndex:1201,
                                alignItems:"center",
                                justifyContent:"center", background:"rgba(255,255,255,0.8)", top:0, left:0, bottom:0, right:0}}>
                                <CircularProgress variant={"indeterminate"} size={100}/>
                                Logging in
                            </div>
                        }
                        <div style={{height:"30px"}}>
                        </div>
                        <Typography variant={"h4"} align={"center"} style={{color:"#044E54"}}>
                            CRCWSC Management Actions
                        </Typography>


                        <div style={{height:"50px"}}>
                        </div>

                        <TextField style={{margin:"2% 2%",width:"95%"}} variant={"outlined"}
                                   label={"Username"}
                                   InputLabelProps={{shrink:true}}
                                   InputProps={{startAdornment:<InputAdornment position={"start"}><Person/></InputAdornment>}}
                                   placeholder={"xxxxx@hotmail.com"}
                                   onChange={this.handleTextChange("username")}
                                   fullWidth/>
                        <TextField style={{margin:"2% 2%",width:"95%"}} variant={"outlined"}
                                   label={"Password"}
                                   InputLabelProps={{shrink:true}}
                                   InputProps={{startAdornment:<InputAdornment position={"start"}><Lock/></InputAdornment>}}
                                   onChange={this.handleTextChange("password")}
                                   fullWidth
                                   type={"password"} />
                        <div style={{display:"flex", justifyContent:"flex-end",marginRight:"10px"}}>
                            <Link style={{color:"#9FB3C8"}}>Forgot Password?</Link>
                        </div>
                        <div style={{height:"50px"}}>
                        </div>
                        <CardActions>
                            <Fab onClick={this.handleLoginClick} variant="extended" color="primary" size={"large"} style={{margin:"auto",width:"20%"}}>
                                log in
                            </Fab>
                        </CardActions>

                        <div style={{height:"50px"}}>
                        </div>

                        <Typography variant={"subtitle1"} align={"center"}><Link style={{color:"#0A6C74"}}>New to TDF? Create Account</Link></Typography>
                    </Card>
                </div>
                <div style={{flexBasis:"40%",backgroundColor:"#0A6C74"}}>

                </div>
        </div>;
    }
}


LoginScreen.propTypes = {
    onLogin: PropTypes.func,
}


export default LoginScreen;
