/**
 * Created by User on 5/11/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    RadialBar, RadialBarChart,
    ResponsiveContainer, Tooltip
} from "recharts";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {calculate_continuum} from "../Continuum";
import {indicators} from "./ManagementActionPanel";
import IndicatorAccordian from "./IndicatorAccordian";
import {Warning} from "@material-ui/icons";
import MaterialTooltip from "@material-ui/core/Tooltip";
import VisualSummary from "./VisualSummary";
import {formatIndicators} from "../IndicatorFormatter";
import {Tabs} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";

const radarPresentationData = [

    {
        "subject": "Ensure good water sensitive guidance"
    },
    {
        "subject": "Increase community capital"
    },
    {
        "subject": "Achieve equity of essential services"
    },
    {
        "subject": "Improve productivity and resource efficiency"
    },
    {
        "subject": "Improve ecological health"
    },
    {
        "subject": "Ensure quality urban space"
    },
    {
        "subject": "Promote adaptive infrastructure"
    }
]

const radialPresentationData = [
    {
        "name": "Water Supply City",
        "fill": "#ff2500"
    },
    {
        "name": "Sewered City",
        "fill": "#FFA500"
    },
    {
        "name": "Drained City",
        "fill": "#daff00"
    },
    {
        "name": "Waterway City",
        "fill": "#66ff7c"
    },
    {
        "name": "Water Cycle City",
        "fill": "#00ff25"
    },
    {
        "name": "Water Sensitive City",
        "fill": "#00e521"
    },
]


class ImpactPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            strategyName: "",
            current_tab: 0,
            strategyDescription: "",
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    handleSubmit = () => {
        this.setState({submitting:false})
        this.props.submitStrategyCallback(this.state.strategyName, this.state.strategyDescription)
    }


    calculateRadial = (base, actions) => {
        return base.map((v,i) => {
            return v.map((o,j) => {

                if(actions.length === 0){
                    return o.rating
                }
                return Math.max(...actions.map(d => d.ratings[i][j].rating_diff + o.rating))
            }).reduce((p,c) => p + c, 0)/v.length;
        })
    }

    calculateRadialConflict = (base, actions) => {
        return base.map((v,i) => {
            return v.map((o,j) => {

                if(actions.length === 0){
                    return o.rating
                }
                return actions.map(d => d.ratings[i][j].rating_diff).reduce((prev, current) => current !== 0 ? prev + 1: prev, 0);
            }).reduce((p, c) => p || c > 1, false);
        })
    }

    renderIndicatorContent = (summary, detail_list, index, index_data) => {
        return <IndicatorAccordian
            editable={false}
            ratingBaseValues={this.props.baseRatings[index]}
            key={index}
            impact={true}
            ratingValues={index_data}
            summary={summary}
            detailList={detail_list}/>
    }

    render() {
        let radial, radar, maxed_indicators;
        let base_radar, target_radar;
        let radial_conflict = [];
        if (this.props.managementActions.length > 0) {
            radial_conflict = this.calculateRadialConflict(this.props.baseRatings, this.props.managementActions);
            radial = calculate_continuum(this.props.baseRatings, this.props.managementActions).slice(1);
            radar = this.calculateRadial(this.props.baseRatings, this.props.managementActions);
            base_radar = this.calculateRadial(this.props.baseRatings, []);
            target_radar = this.calculateRadial(this.props.targetRatings || [], []);
            maxed_indicators = this.props.managementActions[0].ratings.map((ratings, i) => {
                return ratings.map((r, j) => {
                    return Math.max(...this.props.managementActions.map(ma => ma.ratings[i][j].rating_diff))
                })
            })
        }else{
            radial=calculate_continuum(this.props.baseRatings, []).slice(1);
            radar=this.calculateRadial(this.props.baseRatings, []);
            base_radar=this.calculateRadial(this.props.baseRatings, []);
            target_radar = this.calculateRadial(this.props.targetRatings||[], []);
            maxed_indicators = [
                [0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0],
                [0, 0, 0],
                [0, 0, 0, 0, 0, 0],
            ];

        }



        return <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
            <Dialog open={this.state.submitting} maxWidth={"lg"} fullWidth>
                <DialogTitle>
                    Submit action
                </DialogTitle>
                <DialogContent dividers style={{height:"50vh", padding:0, display:"flex"}}>
                    <div style={{flex:1, padding:"10px"}}>
                        Selected actions:
                        {
                            this.props.managementActions.map(ma => {
                                return <div>
                                    {ma.name}
                                </div>
                            })
                        }
                    </div>
                    <Divider orientation={"vertical"}/>
                    <div style={{flex:3, margin:"10px", boxSizing:"border-box", display:"flex", flexDirection:"column"}}>
                        Action Group
                        <div style={{padding:"10px"}}>
                            <TextField
                                value={this.state.strategyName}
                                onChange={_=>this.setState({strategyName: _.target.value})}
                                fullWidth variant={"outlined"} label={"Group name"}/>
                        </div>
                        <div style={{padding:"10px", flex:1}}>
                            <TextField
                                value={this.state.strategyDescription}
                                onChange={_=>this.setState({strategyDescription: _.target.value})}
                                style={{height:"100%"}}
                                inputProps={{style:{height:"100%"}}}
                                InputProps={{style:{height:"100%"}}}
                                fullWidth multiline rows={5}  variant={"outlined"} label={"Group description"}/>
                        </div>

                        <div style={{padding:"10px",}}>
                            <TextField
                                style={{height:"100%"}}
                                inputProps={{readOnly:true, style:{height:"100%"}}}
                                InputProps={{style:{height:"100%"}}}
                                value={this.props.managementActions.reduce((p, c) => p + parseFloat(c.cost), 0)}
                                fullWidth  variant={"outlined"} label={"Cost"}/>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color={"secondary"} onClick={_=>this.setState({submitting:false})}>cancel</Button>
                    <Button variant={"outlined"} color={"primary"} onClick={this.handleSubmit}>confirm</Button>
                </DialogActions>
            </Dialog>
            <Tabs value={this.state.current_tab} onChange={(e,_) => this.setState({current_tab: _})}>
                <Tab label={"index"}/>
                <Tab label={"indicators"}/>
                <Tab label={"tdf"}/>
            </Tabs>
            {
                this.state.current_tab === 2 &&
                <VisualSummary
                    tdfProjects={this.props.tdfProjects}
                    managementActions={this.props.managementActions} />
            }
            {this.state.current_tab === 0 && <div style={{flex:1, display:"flex"}}>

                    <div style={{flex:1, position:"relative"}}>

                    <ResponsiveContainer width={"100%"} height={"100%"}>

                        <RadarChart outerRadius={"50%"} data={radar.map((v,i) => ({"T": target_radar[i],"D": base_radar[i], "V":v, ...radarPresentationData[i]}))}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="subject"
                                            tick={_=><foreignObject style={{
                                                fontSize:"0.6em",
                                            }}
                                                                                    x={_.textAnchor === "start" ? _.x : _.textAnchor === "end" ? _.x - 100: _.x - 50}
                                                                                    y={_.payload.coordinate > 0 || _.payload.coordinate < -180 ? _.y - 100: _.y}
                                                                                    height={100}
                                                                                    width={100}>
                                                <div style={{width:"100%", height:"100%",
                                                    display:"flex",
                                                    flexDirection:"column",}}>
                                                    {_.payload.coordinate > 0 || _.payload.coordinate < -180 ? <div style={{flex:1}}/> : ""}
                                                    <div style={{textAlign: _.textAnchor === "start" ? "left": _.textAnchor === "end"?"right": "center"}}>{_.payload.value}</div>
                                                </div>
                                            </foreignObject>}/>

                            <PolarRadiusAxis tickCount={6} angle={61} domain={[0, 5]} />
                            <Radar animationDuration={200}  dataKey="V" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                            <Radar animationDuration={200}  dataKey="D" fill="none"
                                   stroke="rgb(0,89,200)"
                                   strokeWidth={2}
                                   strokeDasharray={4} fillOpacity={0.6} />
                            <Radar animationDuration={200}  dataKey="T" fill="none"
                                   stroke="rgb(255,89,0)"
                                   strokeWidth={2}
                                   strokeDasharray={4} fillOpacity={0.6} />

                            <Tooltip formatter={(value, name, props) => {
                                return [Math.round(4*value)/4, {"D": "base", "T":"target", "V": "with actions"}[name]]
                            }}/>

                        </RadarChart>
                    </ResponsiveContainer>
                    </div>
                    <div style={{flex:1, position:"relative"}}>

                        { radial_conflict.some(v=>v) &&
                            <div style={{zIndex: 1201, position:"absolute", right:80, top:10}}>
                                <MaterialTooltip  title={"Two or more actions impact the same indicator, these diagrams show the maximum rating"}>
                                    <Warning style={{color:"#f8c22d"}}/>
                                </MaterialTooltip>
                            </div>
                        }
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <RadialBarChart
                                cx={"30%"}
                                cy={"50%"}
                                innerRadius="20%"
                                outerRadius="75%"
                                data={radial.map((v,i) => ({...radialPresentationData[i], "rating": v}))}
                                startAngle={90}
                                endAngle={-270}
                            >
                                <RadialBar minAngle={15} cornerRadius={10}  background clockWise={false} dataKey='rating' />
                                <Legend formatter={(l, p) => l + " (" + p.payload.rating.toFixed() + "%)"} iconSize={10} width={230} height={140} layout='vertical' verticalAlign='middle' align="right" />
                                <Tooltip formatter={_=>_.toFixed()} labelFormatter={_=>radialPresentationData[_].name}/>
                            </RadialBarChart>
                        </ResponsiveContainer>
                    </div>

            </div>}
            {this.state.current_tab === 1 && <div style={{display:"flex", flex:1, flexDirection:"column"}}>
                <div style={{padding: "10px", display: "flex", justifyContent: "center"}}>
                    <TextField label={"Cost"} size={"small"} variant={"outlined"} InputProps={{readOnly: true}}
                               value={this.props.managementActions.reduce((p, c) => p + parseFloat(c.cost), 0)}/>
                </div>
                <div style={{flex: 1, position: "relative"}}>
                    <div style={{position: "absolute", top: 0, right: 0, bottom: 0, left: 0, overflowY: "auto"}}>
                        <div style={{display: "flex"}}>
                            {this.renderIndicatorContent(indicators[0].summary, indicators[0].details, 0, maxed_indicators[0])}
                            {this.renderIndicatorContent(indicators[1].summary, indicators[1].details, 1, maxed_indicators[1])}
                        </div>
                        <div style={{display: "flex"}}>
                            {this.renderIndicatorContent(indicators[2].summary, indicators[2].details, 2, maxed_indicators[2])}
                            {this.renderIndicatorContent(indicators[3].summary, indicators[3].details, 3, maxed_indicators[3])}

                        </div>
                        <div style={{display: "flex"}}>
                            {this.renderIndicatorContent(indicators[4].summary, indicators[4].details, 4, maxed_indicators[4])}
                            {this.renderIndicatorContent(indicators[5].summary, indicators[5].details, 5, maxed_indicators[5])}

                        </div>
                        <div style={{display: "flex"}}>
                            {this.renderIndicatorContent(indicators[6].summary, indicators[6].details, 6, maxed_indicators[6])}

                        </div>
                    </div>
                </div>
            </div>
            }
            <div style={{display:"flex", padding:"10px"}}>
                <div style={{flex:1}}/>
                <Button variant={"outlined"} disabled={this.props.managementActions.length === 0} color={"primary"} size={"small"}
                    onClick={_=>this.setState({
                        submitting: true
                    })}
                >Create Action Group</Button>
            </div>
        </div>;
    }
}


ImpactPanel.propTypes = {
    managementActions: PropTypes.array,
}


export default ImpactPanel;
