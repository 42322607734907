/**
 * Created by User on 11/12/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid/Grid";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Button from "@material-ui/core/Button/Button";
import {formatIndicators} from "../IndicatorFormatter";
import matrix_data from "../matrix/data_gen";

const styles = {
    scorePanelContainer: {
        background: "white",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "1%",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    divider: n => ({
        height: n + "%"
    }),
}

function lerpColor(a, b, amount) {

    var ah = parseInt(a.replace(/#/g, ''), 16),
        ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
        bh = parseInt(b.replace(/#/g, ''), 16),
        br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab);

    return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

export const indicators = [
    {
        summary:"1. Ensure good water sensitive governance",
    },
    {
        summary:"2. Increase community capital",
    },
    {
        summary: "3. Achieve equity of essential services",
    },
    {
        summary: "4. Improve productivity and resource efficiency",
    },
    {
        summary: "5. Improve ecological health",
    },
    {
        summary: "6. Ensure quality urban space",
    },
    {
        summary: "7. Promote adaptive infrastructure",
    }
];

class VisualSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectFilter: [],
            selectCell: [],
            currentProject: "",
            esOpen: false,
            selectedPracChanges:[],
            highlightedEs: null,
        }
        this.generateData = this.generateData.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleCellClick = this.handleCellClick.bind(this);
    }

    handleCellClick(row, col){
        if(this.state.selectCell.length > 0){
            if(this.state.selectCell[0] === row && this.state.selectCell[1] === col){
                this.setState({
                    selectCell:[],
                });
                return;
            }
        }
        this.setState({
            selectCell: [row,col],
        })
    }

    toggleFilter(id){
        let index = this.state.projectFilter.indexOf(id);
        if(index === -1){
            this.setState({
                projectFilter: [id, ...this.state.projectFilter]
            })
            return;
        }else{
            let new_filter = [...this.state.projectFilter];
            new_filter.splice(index, 1);
            this.setState({
                projectFilter: new_filter
            })
        }
    }

    static getBackgroundColor(priority) {
        return ["lightgreen", "yellow", "red"][priority];
    }


    generateData(){
        let sums = this.props.matrixData.rows.map(v => {
            return v.elements.map(k=>0);
        });

        this.props.practiceChanges.map(v => {

            if(this.state.projectFilter.indexOf(v.id) !== -1){
                return;
            }
            v.matrixData.entries.map((v,i) => {
                v.map((k,j) => {
                    if(k.priority === null){
                        sums[i][j] += 3;
                    }else {
                        sums[i][j] += k.priority;
                    }
                })
            })

        })
        sums = sums.map(v => v.map(k => k/this.props.practiceChanges.length));
        let max = sums.reduce((acc, v) => Math.max(acc, v.reduce((acc2, v2) => Math.max(acc2, v2)), 0), 0);
        let min = sums.reduce((acc, v) => Math.min(acc, v.reduce((acc2, v2) => Math.min(acc2, v2)), max), max);
        if(max === min){
            return sums.map(v => v.map(k => {
                return {
                    backgroundColor: "grey"
                }
            }));
        }
        sums = sums.map(v => v.map(k => 5 - Math.min(5, Math.floor(6*(max - k)/(max - min)))));
        return sums.map((v,i) => v.map((k, j) => {
            return {
                border: i === this.state.selectCell[0] && j === this.state.selectCell[1] ? "solid 5px blue": "none",
                backgroundColor: ["green", "GreenYellow", "yellow", "orange", "orangered", "red"][k]
            }
        }));

    }

    handleProjChange = (event,child) => {
        this.setState({
            currentProject: event.target.value
        })
    }

    selectPracticeChange = id => _ => {
        let temp = [...this.state.selectedPracChanges];
        let index = temp.indexOf(id);
        if(index < 0){
            temp.push(id);
        }else{
            temp.splice(index, 1);
        }

        this.setState({
            selectedPracChanges: temp,
        })

    }

    renderLegendColumn = () => {
        return <div style={{height:"100%", maxWidth:"20%", background:"white", zIndex:"100", left:"0px", position:"sticky"}}>
            {
                matrix_data.rows.map((row,i) => {
                    let highlight = !this.state.highlight ? false: this.state.highlight.min <= i && this.state.highlight.max >= i
                    return <div style={{ height:(100/6) + "%", padding:"5px", boxSizing:"border-box"}}>
                        <div style={{border:"solid 2px "+"grey", fontSize: "11px", boxSizing:"border-box", padding:"5px", color: "grey", height:"100%", backgroundColor: highlight ? "white": "white"}}>
                            {row.label}
                        </div>
                    </div>
                })
            }
        </div>
    }
    highlight = (ran, i) => {
        this.setState({
            highlight:ran,
            hoveredIndex:i,
        })
    }

    getMatrixRange = (mat) => {
        let min_yellow = null;
        let max_yellow = null;
        for(let row = 0; row < 6; row++){
            for(let col = 0; col < 5; col++){
                let entry = mat.entries[row][col];
                if(entry.priority === 1){

                    if(min_yellow === null || min_yellow > row){
                        min_yellow = row;
                    }
                    if(max_yellow === null || max_yellow < row){
                        max_yellow = row;
                    }

                }
            }
        }

        return {
            min: min_yellow,
            max: max_yellow,
        }
    }

    renderPracChanges = (goals_list,goal_impact_list) => {
        return <div style={{position:"relative", maxHeight:"100%", height:"100%"}}>
            <div style={{position:"absolute", top:0, right:0, bottom:0, left:0, overflow:"auto", display:"flex", flexDirection:"row"}}>
                {
                    this.renderLegendColumn()
                }
                {
                    goals_list.map((ran,i) => {
                        let pct = i/goals_list.length;
                        let color = lerpColor("#fcff24", "#fcff24", pct);
                        if(ran.min === 7 || ran.max === 0){
                            return <div/>
                        }

                        return <div style={{position:"relative"}}>
                            <div style={{pointerEvents: "none",position:"absolute", zIndex:1001, top:0, left:0, right:0, bottom:0}}>
                                {
                                    goal_impact_list[i].map((row, j) => {
                                        return <div
                                            style={{ height:100/6 + "%",
                                                visibility: row.length === 0 ? "hidden": "visible",
                                                position:"relative", padding:"10px", boxSizing:"border-box", }}>
                                            {
                                                row.length === 0 ? "" :
                                                    <div
                                                        onMouseEnter={_=>this.setState({highlightedEs: {es: row, col: i, row:j}})}
                                                        onMouseLeave={_=>this.setState({highlightedEs: null})}
                                                        style={{pointerEvents: "all", width:"40%", height:"100%",  borderRadius:"5px", backgroundColor:"rgb(140,235,135)"}}/>
                                            }
                                            {
                                                <div style={{ display:
                                                        (this.state.highlightedEs === null || this.state.highlightedEs.es.length === 0 || !(this.state.highlightedEs.col === i && this.state.highlightedEs.row === j)) ? "none" : "block",
                                                    zIndex: 1202,background:"gray", color:"white", padding:"10px", position: "absolute", width:"200px", top: 0, left: "100%"}}>
                                                    {
                                                        this.state.highlightedEs === null ? "" :
                                                            (this.state.highlightedEs.col === i && this.state.highlightedEs.row === j) ?
                                                                this.state.highlightedEs.es.map(es => {
                                                                    return <div>
                                                                        {es.text}
                                                                    </div>
                                                                })
                                                                : ""
                                                    }
                                                </div>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                            {/*<div style={{height:0 + "%",}}/>*/}
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        color:"black",*/}
                            {/*        boxSizing:"border-box",*/}
                            {/*        padding:"5px",*/}
                            {/*        position:"relative",*/}
                            {/*        border:"solid 2px "+ "rgb(140,235,135)",*/}
                            {/*        fontSize: "11px",*/}
                            {/*        minWidth:"70px",*/}
                            {/*        maxWidth:"70px",*/}
                            {/*        background: "rgb(140,235,135)",*/}
                            {/*        borderRadius:"5px",*/}
                            {/*        height:((ran.min)*100/6) + "%",*/}
                            {/*        marginLeft:"80px",*/}
                            {/*        display:"flex",*/}
                            {/*        marginRight:"5px",*/}
                            {/*        flex:"1 1 0",*/}
                            {/*        justifyContent:"center",*/}
                            {/*        alignContent:"center",*/}
                            {/*        textAlign:"center",*/}
                            {/*        flexDirection:"column",*/}
                            {/*    }}*/}
                            {/*>*/}

                            <div style={{height: (ran.min*100/6) + "%",}}/>

                            {/*</div>*/}
                            <div
                                onMouseEnter={_=>this.highlight(ran, i)}
                                onMouseLeave={_=>this.highlight(null)}
                                style={{
                                    color:"black",
                                    boxSizing:"border-box",
                                    padding:"5px",
                                    position:"relative",
                                    border:"solid 2px "+color,
                                    fontSize: "11px",
                                    minWidth:"70px",
                                    maxWidth:"70px",
                                    background: color + "CC",
                                    borderRadius:"5px",
                                    height:((ran.max - ran.min + 1)*100/6) + "%",
                                    marginLeft:"80px",
                                    display:"flex",
                                    marginRight:"5px",
                                    flex:"1 1 0",
                                    justifyContent:"center",
                                    alignContent:"center",
                                    textAlign:"center",
                                    flexDirection:"column",
                                }}
                            >

                                {indicators[i].summary}
                            </div>

                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        color:"black",*/}
                            {/*        boxSizing:"border-box",*/}
                            {/*        padding:"5px",*/}
                            {/*        position:"relative",*/}
                            {/*        border:"solid 2px "+ "rgb(140,0,0)",*/}
                            {/*        fontSize: "11px",*/}
                            {/*        minWidth:"70px",*/}
                            {/*        maxWidth:"70px",*/}
                            {/*        background: "rgb(140,0,0)",*/}
                            {/*        borderRadius:"5px",*/}
                            {/*        height:((6-ran.max-1)*100/6) + "%",*/}
                            {/*        marginLeft:"80px",*/}
                            {/*        display:"flex",*/}
                            {/*        marginRight:"5px",*/}
                            {/*        flex:"1 1 0",*/}
                            {/*        justifyContent:"center",*/}
                            {/*        alignContent:"center",*/}
                            {/*        textAlign:"center",*/}
                            {/*        flexDirection:"column",*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*</div>*/}


                        </div>
                    })
                }
            </div>
        </div>
    }

    renderPracChangesTable = (goals_list) => {
        return <div>
            <Button fullWidth variant={"outlined"} color={"primary"} onClick={_=>this.setState({esOpen: !this.state.esOpen})}>{this.state.esOpen ? "Hide" :"Show"}</Button>
            {!this.state.esOpen ||  <div>
                <div style={{display:"flex",borderBottom:"solid 2px lightgrey", fontSize:"1.1em"}}>
                    <div style={{flex:"1 0 0",padding:"2px", borderRight:"solid 2px lightgrey"}}>Practice Change Name</div>
                    <div style={{minWidth:"20px"}}/>
                    <div style={{flex:"3 0 0"}}>Practice Change</div>
                </div>
                <div>
                    {goals_list.map((ran, i) => {
                        if(this.state.hoveredIndex !== i)return;
                        return <div>
                            {ran.pcs.map(pc => {
                                return <div style={{display:"flex",borderBottom:"solid 2px lightgrey", minHeight:"50px"}}>
                                    <div style={{flex:"1 0 0", width:"25%", padding:"2px", borderRight:"solid 2px lightgrey"}}>{pc.project.name}</div>
                                    <div style={{minWidth:"20px"}}/>
                                    <div style={{flex:"3 0 0"}}>{pc.issue.practice_change_name}</div>
                                </div>
                            })}
                        </div>
                    })}
                </div>
            </div>}
        </div>
    }

    render() {
        let practice_changes = [];
        let _ = this.props.managementActions?.map(f => {
            this.props.tdfProjects.map(tdfp => {
                let t = tdfp.enabling_strategies.filter(es => {
                    return f.enabling_strategies.some(es2 => es.id === es2.tdf_enabling_strategy_id);
                });
                let b = tdfp.practice_changes.filter(pc => {
                    return t.some(es => es.practice_change_id === pc.id);
                });

                if(b) {
                    practice_changes.push(...b.map(pc => ({project: tdfp, issue: pc, es: t.filter(es => pc.id === es.practice_change_id)})));
                }
            })
        })

        let prac_map = {};
        practice_changes.map(pc => prac_map[pc.issue.id] = pc);
        practice_changes = Object.keys(prac_map).map(k => prac_map[k]);

        practice_changes=practice_changes.map(pc => ({...pc, formatted_indicators: formatIndicators(pc.issue.indicators)}))
        let goals = [...new Array(7)].map(_=>({min:7,max:0, pcs:[]}));
        let goal_impact_list = [...new Array(7)].map(_=>{
            return [...new Array(6)].map(_=>[]);
        })

        // Sort count elements
        practice_changes.map(pc => {
            let aff_goals = [...new Set(pc.formatted_indicators.map(v => v[0]))];
            pc.es.map(es => {
                es.cell_map.map((row, i) => {
                    row.map((impacted, j) => {
                        if(impacted) goal_impact_list[aff_goals[0]][j].push(es);
                    })
                })
            })
            pc.issue.practice_change_data.entries.map((row,i) => {

                if(row.some(s => s.priority === 1)){
                    aff_goals.map(v => {
                        goals[v].min = Math.min(goals[v].min, i+1)
                        goals[v].max = Math.max(goals[v].max, i+1)
                        goals[v].rows = goals[v].rows + 1
                        goals[v].medium = goals[v].medium + row.filter(x => x === 2).length
                        goals[v].pcs.push(pc);
                    })
                }
            })
        })

        console.log("goals",goals, practice_changes, goal_impact_list )
        return (
            <div style={{height:"100%", width:"100%", paddingTop:"10px", paddingBottom:"10px", display:"flex", flexDirection:"column"}}>
                <div style={{flex:"1 0 0", transition:"ease 0.3s all" , display:"flex",}}>
                    <Grid container>

                        <Grid  item xs={12}>
                            {this.renderPracChanges(goals, goal_impact_list)}
                        </Grid>

                    </Grid>
                </div>
                <div style={{flex:(this.state.esOpen ? "1" : "0")  +" 0 36px", transition:"ease 0.3s all"}}>
                    {/*<div style={{height:"100%", width:"100%", overflowY:"auto"}}>*/}
                    {/*    {this.renderPracChangesTable(goals)}*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}


VisualSummary.propTypes = {}

export default VisualSummary;
