import React from 'react';
import {PropTypes} from "prop-types"
import MatrixCell from "./MatrixCell"
import data_gen from "./data_gen";


const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}


const buildMatrix =()=>({
    entries: [...Array(6).keys()].map(
        _=>[...Array(5).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(5).keys()].map(_=>""),
})

const styles = {
    matrixContainer:{
        flex:"1",
        display:"flex",
        flexDirection:"column",
    },
    matrixRow: {
        marginBottom:"0.5%",
        flex:"1",
        display:"flex",
        flexDirection:"row",
    }
}
class Matrix extends React.Component {

    constructor(props) {
        super(props);
        this.renderRow = this.renderRow.bind(this)
        this.renderRows = this.renderRows.bind(this)
    }

    renderRow(row, rowindex) {
        let items = [(<MatrixCell
            key={"left" + rowindex}
            matrixId={this.props.matrixId}
            heading
            cell={row}
            row={rowindex}
            col={-1}>
        </MatrixCell>)];

        for (let i = 0; i < row.elements.length; i++) {

            if (row.elements[i] === null) {
                items.push(
                    (<MatrixCell
                        key={i}
                        matrixId={this.props.matrixId}
                        disabled
                        row={rowindex}
                        col={i}>
                        {row.elements[i]}
                    </MatrixCell>)
                )
            } else {
                items.push(
                    (<MatrixCell
                        key={i}
                        matrixId={this.props.matrixId}
                        cell={row.elements[i]}
                        priority={this.props.plainRender && !this.props.clickable && !this.props.cellPropList}
                        priorityEditable={!this.props.plainRender}
                        matrixForceData={this.props.matrixForceData}
                        onClickCallback={_=>this.props.onCellClick(rowindex, i)}
                        clickable={this.props.clickable}
                        custom={!!this.props.cellPropList}
                        cellProps={this.props.cellPropList ? this.props.cellPropList[rowindex][i]: null}
                        highlight={this.props.highlightList ?
                            this.props.highlightList[rowindex][i] :
                            null
                        }
                        row={rowindex}
                        col={i}>
                    </MatrixCell>)
                )

            }
        }
        return (
            <div key={"row"+rowindex} style={styles.matrixRow}>{items}</div>
        );

    }

    renderRows(data) {
        let rows = [];
        rows.push(
            <div key={-1} style={styles.matrixRow}>{
                data.heading_row.map((s, i) => {
                    if (i === 0) {
                        return (<MatrixCell key={i} col={-1} row={-1}
                                            cell={s}
                                            matrixForceData={this.props.matrixForceData}
                                            matrixId={this.props.matrixId}
                                            heading>

                        </MatrixCell>);

                    } else {
                        return (<MatrixCell
                            matrixId={this.props.matrixId}
                            key={i}
                            cell={s}
                            matrixForceData={this.props.matrixForceData}
                            col={i} row={-1}
                            heading>
                        </MatrixCell>);
                    }
                })}
            </div>
        )

        for (let i = 0; i < data.rows.length; i++) {
            rows.push(this.renderRow(data.rows[i], i))
        }

        if (this.props.plainRender === true && this.props.justifications !== true) {
            return rows;
        }

        rows.push(<div key={"rowjusts"} style={{...styles.matrixRow, marginBottom: "none"}}>{
            data.heading_row.map((s, i) => {
                if (i === 0) {
                    return (<MatrixCell key={"just" + i} col={-1} row={-1}

                                        matrixForceData={this.props.matrixForceData}
                                        matrixId={this.props.matrixId} heading cell={{text:"Justifications"}}></MatrixCell>);

                } else {
                    return (<MatrixCell key={"just" + i} col={i} row={-1}
                                        matrixId={this.props.matrixId}
                                        cell={s}

                                        matrixForceData={this.props.matrixForceData}
                                        justificationReadonly={this.props.plainRender}
                                        colTitle={data.heading_row[i]} justification></MatrixCell>);
                }
            })}
        </div>);
        return rows;
    }

    render() {
        let mat_data = data_gen;
        if((this.props.matrixForceData === null  || this.props.matrixForceData === undefined)
            && (this.props.matrixInstance === null || this.props.matrixInstance === undefined)
            && !this.props.cellPropList){
            return <div/>
        }
        let rows = this.renderRows(mat_data);
        return (
            <div style={styles.matrixContainer}>
                {rows}
            </div>
        )
    }
}

Matrix.propTypes = {
    plainRender: PropTypes.bool,
    clickable: PropTypes.bool,
    onCellClick: PropTypes.func,
    highlightList: PropTypes.array,
    cellPropList: PropTypes.array,
    justifications: PropTypes.bool,
    matrixForceData: PropTypes.object,
    matrixId: PropTypes.number, //id of practice change to use matrix from
};

export default Matrix;