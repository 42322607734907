/**
 * Created by User on 5/11/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

class ManagementActionsPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAction: null
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    render() {
        return <div style={{display:"flex", flexDirection:"row", width:"100%", height:"100%"}}>
            <div style={{flex:1, position:"relative", height:"100%"}}>
                <div style={{position:"absolute", top:0, bottom:0, right:0, left:0, overflowY:"auto"}}>
                    <div style={{position:"sticky", top:0, background:"white", zIndex:1201}}>
                        <div style={{padding:"5px"}}> Management Actions</div>

                        <Divider orientation={"horizontal"}/>
                    </div>

                    <List dense>
                        {
                            [...new Array(20)].map((f,i) => {
                                return <ListItem dense button selected={this.state.selectedAction === i} onClick={_=>this.setState({selectedAction: i})}>
                                    <ListItemText>Management Action {i}</ListItemText>
                                </ListItem>
                            })
                        }
                    </List>
                </div>
            </div>
            <Divider orientation={"vertical"}/>
            <div style={{flex:3}}>
                {
                    this.state.selectedAction === null ?
                        <div style={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                            select action from list
                        </div>
                        :
                        <div style={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center", color:"red", fontWeight:"bolder"}}>
                            TODO
                        </div>
                }
            </div>
        </div>;
    }
}


ManagementActionsPreview.propTypes = {}


export default ManagementActionsPreview;
