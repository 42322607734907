/**
 * Created by User on 5/11/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import {Add, Delete, Remove} from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import IndicatorAccordian from "./IndicatorAccordian";

export const indicators = [
    {
        summary:"1. Ensure good water sensitive governance",
        details: [
                "1.1. Knowledge, skills and organisational capacity",
                "1.2. Water is key element in city planning and design",
                "1.3. Cross-sector institutional arrangements and processes",
                "1.4. Public engagement, participation and transparency",
                "1.5. Leadership, long-term vision and commitment",
                "1.6. Water resourcing and funding to deliver broad societal value",
                "1.7. Equitable representation of perspectives"
            ]
    },
    {
        summary:"2. Increase community capital",
        details: [
            "2.1. Water literacy",
            "2.2. Connection with water",
            "2.3. Shared ownership, management and responsibility of water assets",
            "2.4. Community preparedness and response to extreme events",
            "2.5. Indigenous involvement in water plan"
        ]
    },
    {
        summary:"3. Achieve equity of essential services",
        details: [
            "3.1. Equitable access to safe and secure potable water supply",
            "3.2. Equitable access to safe and reliable sanitation",
            "3.3. Equitable access to flood protection",
            "3.4. Equitable and affordable access to amenity values of water-related assets",
        ]
    },
    {
        summary:"4. Improve productivity and resource efficiency",
        details: [
            "4.1. Benefits across other sectors because of water-related services",
            "4.2. Low GHG emission in water sector",
            "4.3. Low end-user potable water demand",
            "4.4. Water-related economic and commercial opportunities",
            "4.5. Maximised resource recovery",
        ]
    },
    {
        summary:"5. Improve ecological health",
        details: [
            "5.1. Healthy and biodiverse habitat",
            "5.2. Surface water quality and flows",
            "5.3. Groundwater quality and replenishment",
            "5.4. Protect existing areas of high ecological value",
        ]
    },
    {
        summary:"6. Ensure quality urban space",
        details: [
            "6.1. Activating connected pleasant urban green and blue space",
            "6.2. Urban elements functioning as part of the urban water system",
            "6.3. Vegetation coverage",
        ]
    },
    {
        summary:"7. Promote adaptive infrastructure",
        details: [
            "7.1. Diverse fit-for-purpose water supply system",
            "7.2. Multi-functional water system infrastructure",
            "7.3. Integration and intelligent control",
            "7.4. Robust infrastructures",
            "7.5. Infrastructure and ownership at multiple scales",
            "7.6. Adequate maintenance",
        ]
    }
];

export const detail_ids = [
    [419, 420, 421, 422, 423, 424, 425],
    [426, 427, 428, 431, 432],
    [433, 434, 435, 436],
    [437, 438, 439, 440, 442],
    [443, 444, 445, 446],
    [447,448,449],
    [450,451,452,453,454,456],
]

let roundOff = (num, places) => {
    const x = Math.pow(10,places);
    return Math.round(num * x) / x;
}

class ManagementActionPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPracticeChange: 0,
            selectedEnablingStrategy: false,
            addingTDFEnablingStrategy: false,
            ma_data: {
                name: props.managementAction?.name || "",
                cost: props.managementAction?.cost || "",
                description: props.managementAction?.desc || "",
                detailed_desc: props.managementAction?.detailed_desc || "",
                justification: props.managementAction?.justification || "",
                rating_values:props.managementAction ?
                    props.managementAction.ratings.map(v => {
                        return v.map(p => p.rating_diff)
                    })
                    :
                    [
                        [0, 0, 0, 0, 0, 0, 0],
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0],
                        [0, 0, 0, 0, 0, 0],
                ]
            },
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.managementAction?.id !== this.props.managementAction?.id){
            this.setState({

                edited: false,
                ma_data: {
                    ...this.state.ma_data,
                    name: this.props.managementAction?.name || "",
                    cost: this.props.managementAction?.cost || "",
                    description: this.props.managementAction?.desc ||"",
                    detailed_desc: this.props.managementAction?.detailed_desc ||"",
                    justification: this.props.managementAction?.justification ||"",
                    rating_values: this.props.managementAction ?
                        this.props.managementAction.ratings.map(v => {
                            return v.map(p => p.rating_diff)
                        })
                        :
                        [
                            [0, 0, 0, 0, 0, 0, 0],
                            [0, 0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 0, 0],
                            [0, 0, 0, 0, 0, 0],
                        ]
                },
            })
        }
    }

    setRatingValue = (major_index) => (index,diff, base_val) => {
        this.setState({
            edited:true,
            ma_data: {
                ...this.state.ma_data,
                rating_values: this.state.ma_data.rating_values.map((v, major) => {
                    return v.map((r, detail) => {
                        if(major_index === major && detail === index){
                            if(roundOff(base_val + diff + r, 1) < 0){
                                return r;
                            }

                            if(base_val + diff + r > 5){
                                return r;
                            }

                            return r + diff;

                        }
                        return r
                    })
                })
            }
        })
    }

    renderIndicatorContent = (summary, detail_list, index) => {
        let filter = null;
        // if(this.props.indicatorFilter && !this.props.indicatorFilter.every(v => v.every(v => !v))){
        //     filter = this.props.indicatorFilter[index]
        //     if(filter.every(v=>!v)){
        //         return <div/>
        //     }
        // }

        return <IndicatorAccordian
            setRatingValueCallback={this.setRatingValue(index)}
            renderFilter={filter}
            editable={true}
            ratingBaseValues={this.props.city_detail_data[index]}
            ratingValues={this.state.ma_data.rating_values[index]}
            key={index}
            summary={summary}
            detailList={detail_list}/>
    }

    handleLinkEnablingStrategy = (id) => {
        this.setState({
            addingTDFEnablingStrategy: false
        })
        this.props.linkEnablingStrategyCallback(this.props.managementAction.id, [id])

    }

    handleDelete = () => {
        this.props.deleteCallback(this.props.managementAction.id)
    }

    handleSave = () => {
        this.props.handleSaveCallback(
            this.props.managementAction.id,
            this.state.ma_data.name,
            this.state.ma_data.description,
            this.state.ma_data.detailed_desc,
            this.state.ma_data.justification,
            this.state.ma_data.cost,
            this.state.ma_data.rating_values.map((_,i) => {
                return _.map((v,j) => {
                    return {"rating": v, "detail_id": detail_ids[i][j]}
                })
            }),
        )
    }

    setMAValue = (k, num=false) => v => {

        if(num){
            if(!(/^[+-]?([0-9]*[.])?[0-9]*$/.test(v))){
                return;
            }
        }
        this.setState({
            ma_data: {
                ...this.state.ma_data,
                [k]:v,
            },

            edited: true,
        });
    }

    render() {
        if(this.props.managementAction === null || this.props.managementAction === undefined){
            return <div/>
        }

        let enabling_strategies = [];
        let practice_changes = [];
        let issues = this.props.linked_tdf_projects.flatMap(p => p.practice_changes);
        let _ = issues?.map(f => {
            this.props.linked_tdf_projects.map(tdfp => {

                let t = tdfp.enabling_strategies.filter(es => {
                    return es.practice_change_id === f.id;
                });
                let b = tdfp.practice_changes.find(pc => {
                    return pc.id === f.id;
                });

                if(b) {
                    practice_changes.push({project: tdfp, issue: b});
                }
                let tm = t.map(es => [es,tdfp.practice_changes.find(a => a.id === es.practice_change_id)])

                enabling_strategies.push(...tm.map(t => ({project:tdfp, issue:t[1], enabling_strategy: t[0]})));
            })
        })


        return <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
            <Dialog open={this.state.addingTDFEnablingStrategy} maxWidth={"lg"} fullWidth>
                <DialogTitle>
                    Connect TDF Enabling Strategy
                </DialogTitle>
                <DialogContent dividers style={{padding:"0px", display:"flex"}}>
                    <div style={{flex:1, padding:"10px"}}>
                        TDF Practice Changes
                        <div>
                            <List>
                                {
                                    practice_changes.map((m, i) => {
                                        return <ListItem selected={this.state.selectedPracticeChange === i} onClick={_=>this.setState({selectedEnablingStrategy: null, selectedPracticeChange: i})} dense button>
                                            <ListItemText secondary={
                                                <div>
                                                    <div>
                                                        {m.issue.practice_change_name}
                                                    </div>
                                                    <div>
                                                        {m.project.name}
                                                    </div>
                                                </div>
                                            }>
                                                {m.issue.issue_name}
                                            </ListItemText>
                                        </ListItem>
                                    })
                                }
                            </List>
                        </div>
                    </div>
                    <Divider orientation={"vertical"}/>
                    <div style={{flex:3, padding:"10px", display:"flex", flexDirection:"column"}}>
                        Enabling Strategies
                        <div style={{display:"flex"}}>
                            <List>
                                {
                                    enabling_strategies.filter(es => {
                                        return es.issue.id === practice_changes[this.state.selectedPracticeChange].issue.id
                                    }).map(es => {
                                        return <ListItem
                                            onClick={_=>this.setState({selectedEnablingStrategy: es.enabling_strategy.id})}
                                            dense
                                            button
                                            selected={es.enabling_strategy.id === this.state.selectedEnablingStrategy}>
                                            <ListItemText>
                                                {es.enabling_strategy.text}
                                            </ListItemText>
                                        </ListItem>
                                    })
                                }
                            </List>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={_=>this.setState({addingTDFEnablingStrategy: false})}>cancel</Button>
                    <Button color={"secondary"} disabled={this.state.selectedEnablingStrategy === null} onClick={_=>this.handleLinkEnablingStrategy(enabling_strategies.find(es => es.enabling_strategy.id === this.state.selectedEnablingStrategy).enabling_strategy.id)}>confirm</Button>
                </DialogActions>
            </Dialog>
            <div style={{padding:"10px 20px", display:"flex"}}>
                {this.props.managementAction.name}
                <div style={{flex:1}}/>
                <Button size={"small"} variant={"outlined"} color={"secondary"}
                        onClick={this.handleDelete}
                startIcon={<Delete/>}>delete</Button>
            </div>
            <div style={{flex:1, padding:"5px", display:"flex", flexDirection:"column"}}>
                <div style={{flex:2, display:"flex", flexDirection:"row", padding:"10px 20px"}}>
                    <div style={{flex:1, display:"flex", flexDirection:"column"}}>

                        <div style={{flex:2}}>
                            <TextField style={{height:"100%"}}
                                       placeholder={this.props.managementAction.name}
                                       defaultValue={this.props.managementAction.name}
                                       value={this.state.ma_data.name}
                                       onChange={_=>this.setMAValue("name")(_.target.value)}
                                       inputProps={{style:{height:"100%"}}}
                                       InputProps={{style:{height:"100%"}}}
                                       size={"small"} fullWidth label={"Action Name"} variant={"outlined"}/>
                        </div>
                        <div style={{flex:1}}/>
                        <div style={{flex:2}}>
                            <TextField
                                defaultValue={this.props.managementAction.cost}
                                value={this.state.ma_data.cost}
                                onChange={_=>this.setMAValue("cost", true)(_.target.value)}
                                style={{height:"100%"}}
                                inputProps={{style:{height:"100%"}}}
                                InputProps={{
                                style:{height:"100%"},
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }} size={"small"} fullWidth label={"Estimated Cost"} variant={"outlined"}/>
                        </div>
                    </div>
                    <div style={{width:"40px"}}/>
                    <div style={{flex:1}}>
                        <TextField style={{height:"100%"}}
                                   value={this.state.ma_data.description}
                                   inputProps={{style:{height:"100%"}}}
                                   InputProps={{style:{height:"100%"}}}
                                   onChange={_=>this.setMAValue("description")(_.target.value)}
                                   defaultValue={this.props.managementAction.desc}
                                   size={"small"} fullWidth label={"Description"} multiline rows={3} variant={"outlined"}/>
                    </div>
                </div>
                <div style={{flex:3, display:"flex", flexDirection:"row", padding:"0px 20px"}}>

                    <TextField style={{height:"100%"}}
                               value={this.state.ma_data.detailed_desc}
                               inputProps={{style:{height:"100%"}}}
                               InputProps={{style:{height:"100%"}}}
                               onChange={_=>this.setMAValue("detailed_desc")(_.target.value)}
                               defaultValue={this.props.managementAction.detailed_desc}
                               size={"small"} fullWidth label={"Detailed Description"} multiline rows={2} variant={"outlined"}/>
                </div>

                <div style={{flex:3, display:"flex", flexDirection:"row", padding:"20px 20px"}}>

                    <TextField style={{height:"100%"}}
                               value={this.state.ma_data.justification}
                               inputProps={{style:{height:"100%"}}}
                               InputProps={{style:{height:"100%"}}}
                               onChange={_=>this.setMAValue("justification")(_.target.value)}
                               defaultValue={this.props.managementAction.justification}
                               size={"small"} fullWidth label={"Justification"} multiline rows={2} variant={"outlined"}/>
                </div>


                <div style={{flex:3, display:"flex", flexDirection:"row", padding:"0px 20px"}}>
                    <div style={{flex:1, position:"relative", border:"solid 1px #ddd", borderRadius:"4px"}}>
                        <div style={{position:"absolute", top:0, right:0, left:0, bottom:0, overflowY:"auto"}}>
                            <div style={{position:"sticky", top:0, backgroundColor:"white", zIndex:1201}}>
                                <div style={{padding:"5px"}}>
                                    Connected TDF Enabling Strategies
                                </div>
                            </div>
                            {
                                this.props.managementAction.enabling_strategies.length === 0 &&
                                    <div style={{color:"rgba(0,0,0,0.3)", textAlign:"center"}}>
                                        click add button to link issue
                                    </div>
                            }
                            <List dense>
                                {this.props.managementAction.enabling_strategies.map((f,i) => {
                                    let es =  enabling_strategies.find(i => i.enabling_strategy.id === f.tdf_enabling_strategy_id);
                                    console.log("es", this.props.managementAction.enabling_strategies, es, enabling_strategies, this.props)
                                    return <ListItem dense button>
                                        <ListItemText secondary={
                                            <div style={{fontSize:"0.7em"}}>
                                                <div>{es.issue.practice_change_name}</div>
                                                <div>{es.project.name}</div>
                                            </div>
                                        }>
                                            {es.enabling_strategy.text}
                                        </ListItemText>
                                    </ListItem>
                                })}
                            </List>
                        </div>
                        <Fab onClick={_=>this.setState({addingTDFEnablingStrategy: true})} size={"small"} style={{position:"absolute", bottom:10, right:30}}>
                            <Add />
                        </Fab>
                    </div>
                </div>
            <div style={{flex:6}}>
                <div style={{position:"relative", margin:"10px 20px", height:"100%",  border:"solid 1px #ddd", borderRadius:"4px"}}>
                    <div style={{position:"absolute", top:0, right:0, bottom:0, left:0, overflowY:"auto"}}>
                        <div style={{display:"flex"}}>
                            {this.renderIndicatorContent(indicators[0].summary, indicators[0].details, 0)}
                            {this.renderIndicatorContent(indicators[1].summary, indicators[1].details, 1)}
                        </div>
                        <div style={{display:"flex"}}>
                            {this.renderIndicatorContent(indicators[2].summary, indicators[2].details, 2)}
                            {this.renderIndicatorContent(indicators[3].summary, indicators[3].details, 3)}

                        </div>
                        <div style={{display:"flex"}}>
                            {this.renderIndicatorContent(indicators[4].summary, indicators[4].details, 4)}
                            {this.renderIndicatorContent(indicators[5].summary, indicators[5].details, 5)}

                        </div>
                        <div style={{display:"flex"}}>
                            {this.renderIndicatorContent(indicators[6].summary, indicators[6].details, 6)}

                        </div>
                    </div>

                </div>
                </div>
            </div>
            <div style={{display:"flex", padding:"20px"}}>
                <div style={{flex:1}}/>
                <Button style={{flex:1}}
                        size={"small"}
                        variant={"outlined"}
                        onClick={this.handleSave}
                        color={"primary"}
                        disabled={!this.state.edited}>save</Button>
                <div style={{flex:1}}/>
            </div>
        </div>;
    }
}


ManagementActionPanel.propTypes = {
    managementAction: PropTypes.object,
}


export default ManagementActionPanel;
