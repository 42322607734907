/**
 * Created by User on 8/12/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import RadialChart from "./RadialChart";
import CityRadarChart from "./CityRadarChart";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Matrix from "../matrix/Matrix";
import VisualSummary from "./VisualSummary";
import {Delete} from "@material-ui/icons";

class StrategyPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAction: null,
            selectedTab: 0,
            viewingStrategy: null,
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    handleDelete = () => {
        this.props.deleteCallback(this.props.strategy.id)
    }


    renderEnablingStrategies = () => {
        let unique_strategies = {};
        let strategies = this.props.strategy.actions.filter(a => {
            return this.state.selectedAction === null || a.id === this.state.selectedAction
        }).flatMap(v => v.enabling_strategies);
        strategies.map(s => unique_strategies[s.tdf_enabling_strategy_id] = s);


        return <div style={{width:"100%", height:"100%"}}>
            <List>
            {

                Object.keys(unique_strategies).map(v => {
                    return this.props.enablingStrategies.find(es => es.enabling_strategy.id === unique_strategies[v].tdf_enabling_strategy_id)
                }).map(es => {
                    if (es) {
                        return <ListItem dense button
                                         onClick={_ => this.setState({viewingStrategy: es.enabling_strategy.id})}>
                            <ListItemText>

                                {es.enabling_strategy.text}
                            </ListItemText>
                        </ListItem>
                    }
                })
            }
            </List>
        </div>
    }

    render() {
        if(this.props.strategy === undefined || this.props.strategy === null) return "";

        let es = this.props.enablingStrategies.find(es => es.enabling_strategy.id === this.state.viewingStrategy)
        console.log("enablingStrategies", es, this.props.enablingStrategies, this.state.viewingStrategy, this.props.strategy);
        return <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
            <Dialog
                fullWidth
                maxWidth={"xl"}
                open={this.state.viewingStrategy !== null}>
                <DialogTitle>
                    Enabling Strategy
                </DialogTitle>
                <DialogContent style={{height:"70vh"}} dividers>
                    {
                        es !== undefined &&
                            <div>
                                {es.enabling_strategy.text}
                                <div>
                                    <Matrix
                                        clickable={false}
                                        plainRender
                                        highlightList={es.enabling_strategy.cell_map.map(r => {
                                            return r.map(v => v ? {color:"skyblue", size:"4px"} : null)
                                        })}
                                        matrixForceData={es.issue.practice_change_data}/>
                                </div>
                            </div>

                    }
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} size={"small"} color={"secondary"} onClick={_=>this.setState({viewingStrategy: null})}>dismiss</Button>
                </DialogActions>
            </Dialog>

            <div style={{padding:"10px 20px", display:"flex"}}>
                {this.props.strategy.strategy_name}
                <div style={{flex:1}}/>
                <Button size={"small"} variant={"outlined"} color={"secondary"}
                        onClick={this.handleDelete}
                        startIcon={<Delete/>}>delete</Button>
            </div>
            <Divider/>
            <div style={{flex:1, display:"flex", flexDirection:"row"}}>
                <div style={{flex:1, display:"flex", flexDirection:"column"}}>
                    <div style={{padding:"10px"}}>
                        <TextField style={{height:"100%"}}
                                   variant={"outlined"}
                                        inputProps={{style:{height:"100%"}}}
                                        InputProps={{style:{height:"100%"}}} fullWidth contentEditable={false} value={this.props.strategy.strategy_name} label={"Name"}/>
                    </div>
                    <div style={{padding:"10px"}}>

                        <TextField style={{height:"100%"}}
                                   variant={"outlined"}
                                   inputProps={{style:{height:"100%"}}}
                                   InputProps={{style:{height:"100%"}}}  fullWidth contentEditable={false} value={this.props.strategy.strategy_cost} label={"cost"}/>
                    </div>
                </div>
                <div style={{flex:1, padding:"10px"}}>

                    <TextField style={{height:"100%"}}

                               variant={"outlined"}
                               inputProps={{style:{height:"100%"}}}
                               InputProps={{style:{height:"100%"}}}
                               fullWidth contentEditable={false} multiline rows={4} value={this.props.strategy.strategy_desc} label={"description"}/>
                </div>
            </div>
            <div style={{display:"flex", flex:2}}>
                <div style={{flex:1, padding:"10px",  overflowY:"auto", maxHeight: "800px"}}>
                    Actions
                    <List dense>
                    {
                        this.props.strategy.actions.map(a => {
                            return <ListItem dense button
                                             selected={a.id === this.state.selectedAction}
                                             onClick={_=>this.setState({selectedAction: this.state.selectedAction === a.id ? null : a.id })}>
                                <ListItemText>
                                    {a.name}
                                </ListItemText>
                            </ListItem>
                        })
                    }
                    </List>
                </div>
                <Divider orientation={"vertical"}/>
                <div style={{flex:3, padding:"10px", display:"flex", flexDirection:"column"}}>
                    <Tabs value={this.state.selectedTab} onChange={(_,v) => this.setState({selectedTab: v})}>
                        <Tab label={"Indicators"}/>
                        <Tab label={"City State"}/>
                        <Tab label={"Enabling Strategies"}/>
                        <Tab label={"tdf"}/>
                    </Tabs>
                    <div style={{flex:1}}>
                        { this.state.selectedTab === 0 &&
                            <CityRadarChart
                                targetRatings={this.props.targetRatings}
                                baseRatings={this.props.baseRatings}
                                managementActions={
                                    this.state.selectedAction === null ?
                                        this.props.strategy.actions:
                                    this.props.strategy.actions.filter(ma => ma.id === this.state.selectedAction)
                                }/>
                        }
                        { this.state.selectedTab === 1 &&
                            <RadialChart
                                baseRatings={this.props.baseRatings}
                                managementActions={
                                    this.state.selectedAction === null ?
                                        this.props.strategy.actions:
                                        this.props.strategy.actions.filter(ma => ma.id === this.state.selectedAction)}/>
                        }

                        { this.state.selectedTab === 2 &&
                            this.renderEnablingStrategies()
                        }
                        { this.state.selectedTab === 3 &&
                            <VisualSummary
                                tdfProjects={this.props.tdfProjects}
                                managementActions={
                                    this.state.selectedAction === null ?
                                        this.props.strategy.actions:
                                        this.props.strategy.actions.filter(ma => ma.id === this.state.selectedAction)}/>
                        }

                    </div>
                </div>
            </div>
        </div>;
    }
}


StrategyPanel.propTypes = {}


export default StrategyPanel;
