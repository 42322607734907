import React from 'react';
import {PropTypes} from "prop-types"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import MatrixCellPriorityEditable from "./MatrixCellPriorityEditable";
import MatrixCellPriority from "./MatrixCellPriority";
import MatrixCellJustification from "./MatrixCellJustification";
import MatrixCellClickable from "./MatrixCellClickable";
import MatrixCellCustom from "./MatrixCellCustom";
import MatrixCellHeading from "./MatrixCellHeading";
import data_gen from "./data_gen";


export const styles = {
    heading:{
        textAlign:"center",
        fontSize:"1.2em",
        minHeight:"70px"
    },
    priorityEditable: {
        wordBreak:"break-word",
        textAlign:"center",
    },

    wrapper: (i, flex_shrink=1) => ({
        flex:`1 ${flex_shrink} 0%`,
        marginLeft:i === -1? "none":"0.5%"
    }),
    innerWrappersHeading: {
        height:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontSize:"1.3vmin",
        flexGrow:0,
        fontWeight:"bold"
    },
    innerWrappers: {
        margin:"auto",
        height:"100%",
        boxSizing:"border-box",
        transition:"background-color 200ms ease",
        fontSize:"1.4vmin",
    },
    innerWrappersHovered: {
        margin:"auto",
        height:"100%",
        padding:"0.1%",
        backgroundColor:"pink",
        transition:"background-color 200ms ease",
        fontSize:"1.6vim",
    },
    disabled:{
        margin:"auto",
        height:"100%",
        backgroundColor:"lightgrey",
    }
}

class MatrixCell extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hovered:false};
        this.renderHeading = this.renderHeading.bind(this);
        this.renderPriorityEditable = this.renderPriorityEditable.bind(this);
        this.renderDisabled = this.renderDisabled.bind(this);
        this.renderJustification = this.renderJustification.bind(this);
        this.renderPriority = this.renderPriority.bind(this);
        this.renderClickable = this.renderClickable.bind(this);
    }




    renderClickable(highlight) {
        return (
            <div
                  style={{...styles.priorityEditable, ...styles.wrapper(this.props.col)}}>
                <MatrixCellClickable highlight={highlight} matrixId={this.props.matrixId}
                                     onClick={_=>this.props.onClickCallback(this.props.row, this.props.col)} row={this.props.row} col={this.props.col} cell={this.props.cell}/>
            </div>);

    }

    renderHeading(highlight){
        return (
            <div
                style={{...styles.heading, ...styles.wrapper(this.props.col)}}>
                <MatrixCellHeading highlight={highlight} matrixId={this.props.matrixId}
                                     onClick={_=>this.props.onClickCallback(this.props.row, this.props.col)} row={this.props.row} col={this.props.col} cell={this.props.cell}/>
            </div>);
    }

    renderPriority(highlight) {
        return (
            <div
                  style={{...styles.priorityEditable, ...styles.wrapper(this.props.col)}}>
                <MatrixCellPriority matrixForceData={this.props.matrixForceData}  matrixId={this.props.matrixId} highlight={highlight} row={this.props.row} col={this.props.col} cell={this.props.cell} />
            </div>
        )
    }

    renderPriorityEditable(highlight){
        return (
            <div
                  style={{...styles.priorityEditable, ...styles.wrapper(this.props.col)}}>
                <MatrixCellPriorityEditable matrixId={this.props.matrixId} highlight={highlight} row={this.props.row} col={this.props.col} cell={this.props.cell}/>
            </div>
        )
    }


    renderJustification(highlight){

        let data = data_gen;
        return (
            <div
                  style={{...styles.priorityEditable, ...styles.wrapper(this.props.col)}}>
                <MatrixCellJustification matrixId={this.props.matrixId} highlight={highlight} readOnly={this.props.justificationReadonly} cell={this.props.cell} row={this.props.row} col={this.props.col - 1} colTitle={data.heading_row[this.props.col+1]}/>
            </div>
        )
    }


    renderCustom(highlight) {

        return (
            <div
                  style={{...styles.priorityEditable, ...styles.wrapper(this.props.col)}}>
                <MatrixCellCustom matrixId={this.props.matrixId} highlight={highlight} onClick={this.props.onClickCallback} cellStyle={this.props.cellProps} row={this.props.row} col={this.props.col} cell={this.props.cell}/>
            </div>
        )
    }

    renderDisabled(highlight){
        return (
            <div style={{...styles.wrapper(this.props.col)}}>
                <Paper style={styles.disabled}>

                </Paper>
            </div>
        )
    }


    render() {
        let typeProps = [this.props.heading, this.props.custom, this.props.priorityEditable, this.props.disabled, this.props.justification, this.props.priority, this.props.clickable];
        if(typeProps.filter(x=>x===true).length < 1){
            throw new TypeError("Matrix cells must be one of: heading, priorityEditable disabled, justification");
        }
        const highlight = this.props.highlight || {color:"white", size:"0px"};
        if(this.props.heading){
            return this.renderHeading(highlight);
        }else if(this.props.priorityEditable){
            return this.renderPriorityEditable(highlight);
        }else if(this.props.disabled){
            return this.renderDisabled(highlight);
        }else if(this.props.justification){
            return this.renderJustification(highlight);
        }else if(this.props.priority){
            return this.renderPriority(highlight);
        }else if(this.props.clickable){
            if(this.props.onClickCallback === null){
                throw new TypeError("click callback must be provided for clickable cells");
            }
            return this.renderClickable(highlight);
        }else if(this.props.custom){
            if(this.props.cellProps === null){
                throw new TypeError("cell props must be provided for custom cell types,");
            }
            return this.renderCustom(highlight);
        }


    }

}

MatrixCell.propTypes = {
    row: PropTypes.number,
    col: PropTypes.number.isRequired,

    /* TYPE PROPS */

    heading: PropTypes.bool,
    priorityEditable: PropTypes.bool,
    disabled: PropTypes.bool,
    justification: PropTypes.bool,
    justificationReadonly: PropTypes.bool,
    priority: PropTypes.bool,
    clickable: PropTypes.bool,
    custom: PropTypes.bool,

    highlight: PropTypes.object,
    cellProps: PropTypes.object,
    /* INTERACTION PROPS */
    onClickCallback: PropTypes.func,


    /* REDUX PROPS */
    matrixData: PropTypes.object,
    matrixId: PropTypes.number.isRequired,
}

export default MatrixCell;