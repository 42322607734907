import React from 'react';
import {PropTypes} from "prop-types";
import Paper from "@material-ui/core/Paper/Paper";
import MatrixCellPriority from "./MatrixCellPriority"
import {styles} from "./MatrixCell"


const generateEntry = function(){
    return {
        justification: "",
        priority: null,
    }
}


const buildMatrix =()=>({
    entries: [...Array(6).keys()].map(
        _=>[...Array(5).keys()].map(generateEntry)
    ),
    suggestedScore:0,
    overrideScore:0,
    columnJustifications: [...Array(5).keys()].map(_=>""),
})

class MatrixCellClickable extends React.Component {

    constructor(props) {
        super(props);
    }



    render() {
        const highlight = this.props.highlight;

        return (
            <Paper onClick={this.props.onClick} style={{boxSizing: "border-box", cursor:"pointer", border:"solid " + highlight.size + " " + highlight.color,...styles.innerWrappers, backgroundColor: MatrixCellPriority.getBackgroundColor(this.props.priority)}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                    {this.props.cell.text}
                </div>
        </Paper>)
    }
}

MatrixCellClickable.propTypes = {
    cell:PropTypes.string,
    priority: PropTypes.number,
    highlight: PropTypes.object,
    onClick: PropTypes.func,
    matrixId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    let matrix;
    if(ownProps.matrixId !== undefined && ownProps.matrixId !== null){
        matrix = state.practiceChanges.filter(v => v.id === ownProps.matrixId)[0].matrixData;
    }else{
        matrix = buildMatrix()
    }
    let entry = matrix.entries[ownProps.row][ownProps.col];
    return {
        label: state.matrixData.rows[ownProps.row].elements[ownProps.col],
        priority: entry.priority,
        justification: entry.justification
    }

}

const mapDispatchToProps = dispatch => ({})

export default MatrixCellClickable;