
import {fetch} from "whatwg-fetch";

import {tdf_url, url } from "./config.js"


export const DANCE_GET = (url) => {
    return fetch(url, {
        method: "GET",
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}


export const DANCE_GET_FILE = (url, download_filename="download.zip") => {
    return fetch(url, {
        method: "GET",
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(response => {
        console.log(response);
        return response.blob()
    }).then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = download_filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
        }

    );
}
export const DANCE_POST_FILE = (url, file) => {
    let form_data = new FormData();
    form_data.append("file", file);
    form_data.append("file_name", file.name);

    return fetch(url, {
        method: "POST",
        body: form_data,
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());;
}

export const DANCE_POST = (url, data) => {
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}


export const DANCE_PUT = (url, data, handlers) => {
    return fetch(url, {
        method: "PUT",
        mode:"cors",
        body: JSON.stringify(data),
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}

export const DANCE_DELETE = (url) => {
    return fetch(url, {
        method: "DELETE",
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}



const loginUser = (username, password) => {
    return DANCE_POST(url + "/user/login/", {username, password})
}

const checkToken = (username, token) => {
    return DANCE_POST(url + "/user/validate_token/", {username, token})
}

const getProjects = (id) => {
    if(id !== null && id !== undefined){
        return DANCE_GET(url + "/project/" + id + "/")
    }
    return DANCE_GET(url + "/project/")
}

const getProjectsCities = (id) => {
    if(id !== null && id !== undefined){
        return DANCE_GET(url + "/project/" + id + "/cities")
    }
}

const getIndexData = (project_id, city_id) => {
    return DANCE_GET(url + "/index/project/"+project_id+"/city/" + city_id + "/")
}

const getTargetIndexData = () => {
    return DANCE_GET(url + "/index/targets")
}


const getCityIndex = (project_id, city_id) => {
    return DANCE_GET(url + "/index/project/"+project_id+"/city/" + city_id + "/city_index")
}

const getCityDetail = (project_id, city_id) => {
    return DANCE_GET(url + "/city/" + city_id + "/project/"+project_id+ "/details")
}

const getLinkedTDF = (project_id, city_id) => {
    return DANCE_GET(url + "/city/"+city_id+"/project/"+project_id+"/linked_tdf")

}

const getProjectLinkedTDF = (project_id) => {
    return DANCE_GET(url + "/project/"+project_id+"/tdf_projects")
}

const getMAProjects = (project_id) => {
    return DANCE_GET(url + "/ma_project/"+ project_id +"/ma_projects")
}

const addMAProject = (wsi_project_id, city_id, name, tdf_project_id) => {
    return DANCE_POST(url + "/ma_project/", {wsi_project_id: wsi_project_id, city_id: city_id, name: name, tdf_project_id: tdf_project_id})
}

const deleteMAProject = (project_id) => {
    return DANCE_POST(url + "/ma_project/"+ project_id +"/")
}

const addProjectTDF = (project_id, tdf_id, city_id) => {
    return DANCE_POST(url + "/project/"+project_id+"/tdf_projects", {tdf_project_id: tdf_id, city_id: city_id})
}

const addLinkedIssue = (project_id, city_id, issue_id) => {
    return DANCE_POST(url + "/city/"+city_id+"/project/"+project_id+"/linked_tdf", {tdf_issue_id: issue_id})
}

const addEmptyAction = (project_id, city_id, ma_project_id) => {
    return DANCE_POST(url + `/action/project/${project_id}/city/${city_id}/ma_project/${ma_project_id}/`, {empty_action: true})
}

const addAction = (project_id, city_id, ma_project_id, data) => {
    return DANCE_POST(url + `/action/project/${project_id}/city/${city_id}/ma_project/${ma_project_id}/`, {empty_action: false, ...data})
}

const getActions = (project_id, city_id, ma_project_id) => {
    return DANCE_GET(url + `/action/project/${project_id}/city/${city_id}/ma_project/${ma_project_id}/`)
}

const getActionsDatabase = () => {
    return DANCE_GET(url + `/action/database`)
}

const linkEnalbingStrategies = (action_id, project_id, city_id, ids) => {
    return DANCE_POST(url + `/action/${action_id}/project/${project_id}/city/${city_id}/link_enabling_strategy`, {"enabling_strategy_ids":ids})
}

const saveAction = (action_id, project_id, city_id, data) => {
    return DANCE_POST(url + `/action/${action_id}/project/${project_id}/city/${city_id}/update`, data)
}

const deleteAction = (action_id, project_id, city_id) => {
    return DANCE_DELETE(url + `/action/${action_id}/project/${project_id}/city/${city_id}/update`)
}

const addStrategy = (project_id, city_id, ma_project_id, data) => {
    return DANCE_POST(url + `/strategy/project/${project_id}/city/${city_id}/ma_project/${ma_project_id}/`, data)
}

const getStrategies = (project_id, city_id, ma_project_id) => {
    return DANCE_GET(url + `/strategy/project/${project_id}/city/${city_id}/ma_project/${ma_project_id}/`)
}

const deleteStrategy = (strategy_id, project_id, city_id) => {
    return DANCE_DELETE(url + `/strategy/${strategy_id}/project/${project_id}/city/${city_id}/update`)
}


export const test = () => {

    return fetch(tdf_url + "/projects", {
        method: "GET",
        mode:"cors",
        headers: {
            useremail: localStorage.getItem("email"),
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => res.json());
}
const dance_endpoint_injection = (store, func) => {

    return func.bind({
        getState: store.getState,
        dispatch: store.dispatch
    });
}


const noop = _=>{
    throw "Unimplemented api endpoint"
};

class Endpoint {

    constructor(store, handlers) {
        this.get = dance_endpoint_injection(store, handlers.get || noop);
        this.put = dance_endpoint_injection(store, handlers.put || noop);
        this.post = dance_endpoint_injection(store, handlers.post || noop);
        this.delete = dance_endpoint_injection(store, handlers.delete || noop);
    }

}

export const combined = (dict) => {
    let promises = Object.keys(dict).map(f => {
        return dict[f]().then(r => {
            return {k:f, data:r}
        })
    })
    return _=>Promise.all(promises).then(f => {
        let result = {};

        f.map(v => {
            result[v.k] = v.data
        })

        return result;
    })

}

let API = {};

API.login = new Endpoint({},{
    post:loginUser,
});

API.checkToken = new Endpoint({},{
    post:checkToken,
});

API.project = new Endpoint({},{
    get:getProjects,
});

API.projectCities = new Endpoint({},{
    get:getProjectsCities,
});

API.indexData = new Endpoint({},{
    get:getIndexData,
});

API.targetIndexData = new Endpoint({},{
    get:getTargetIndexData,
});

API.cityIndex = new Endpoint({},{
    get:getCityIndex,
});

API.cityDetail = new Endpoint({},{
    get:getCityDetail,
});

API.projectTDF = new Endpoint({},{
    get:getProjectLinkedTDF,
    post: addProjectTDF
});

API.cityTDF = new Endpoint({},{
    get:getLinkedTDF,
    post: addLinkedIssue
});

API.emptyAction = new Endpoint({},{
    post:addEmptyAction,
});
API.createAction = new Endpoint({},{
    post:addAction,
});

API.action = new Endpoint({},{
    get:getActions,
    post:saveAction,
    delete: deleteAction,
});

API.actionDatabase= new Endpoint({},{
    get:getActionsDatabase,
});

API.actionEnablingStrategies = new Endpoint({},{
    post:linkEnalbingStrategies,
});


API.strategy = new Endpoint({},{
    post:addStrategy,
    get: getStrategies,
    delete: deleteStrategy
});

API.MAProject = new Endpoint({},{
    post:addMAProject,
    get:getMAProjects,
    delete: deleteMAProject
});






export default API;
